import {
  JarvisAuthProvider,
  JarvisWebHttpClientRequestOptions
} from '@jarvis/web-http'
import axios, { AxiosInstance } from 'axios'
import { forceLoginHandling } from '@/utils/forceLoginHandling'
import sendOpenTelemetryEvent from '@/helpers/sendOpenTelemetryEvent'
import InfoDatas from '@/types/InfoDatas'
import { DeviceCacheApiClient, Stack } from '@jarvis/web-stratus-client'
import { DeviceCache } from '@/types/DeviceCache'

export default class SubscriptionStateClient {
  httpClient: AxiosInstance
  xCorrelationId: string
  baseURL: string
  billingEndpoint: string
  mfeAuthProvider: JarvisAuthProvider
  stack: Stack
  deviceCacheApiClient: DeviceCacheApiClient
  constructor(
    authProvider: JarvisAuthProvider,
    xCorrelationId: string,
    stack: Stack
  ) {
    this.httpClient = axios
    this.httpClient.defaults.params = {}
    this.mfeAuthProvider = authProvider
    this.xCorrelationId = xCorrelationId
    this.baseURL = process.env.SS_BASE_URL_PROVIDER
    this.stack = stack
    this.deviceCacheApiClient = new DeviceCacheApiClient(
      this.stack,
      this.mfeAuthProvider
    )
  }

  splitHybridSubscription(
    subscriptions: InfoDatas['subscriptionInfo']
  ): InfoDatas['subscriptionInfo'] {
    return subscriptions.reduce((previousValue, current) => {
      const mainDevices = current.entities.filter(
        (entity) =>
          entity.entityType == 'iot-printer' ||
          entity.entityType == 'iot-pc' ||
          entity.entityType == 'chromebook'
      )
      if (mainDevices.length <= 1) {
        previousValue.push(current)
        return previousValue
      }

      mainDevices.sort((a, b) => a.entityType.localeCompare(b.entityType))
      let lastEntityType
      const splitedSubs = mainDevices.reduce(
        (accumulate, curMain, curIndex) => {
          if (curMain.entityType !== lastEntityType) {
            const newSub = { ...current }
            const validEntites = newSub.entities.filter(
              (entity) =>
                curMain.entityId === entity.entityId ||
                curMain?.linkedEntityIds?.includes(entity?.entityId) ||
                curMain.entityType === entity.entityType
            )
            lastEntityType = curMain.entityType

            newSub.entities = validEntites
            newSub['hybridIndex'] = curIndex
            accumulate.push(newSub)
          }
          return accumulate
        },
        [] as InfoDatas['subscriptionInfo']
      )
      return previousValue.concat(splitedSubs)
    }, [] as InfoDatas['subscriptionInfo'])
  }
  async getSubscriptionData(): Promise<InfoDatas['subscriptionInfo']> {
    const stratusToken = await this.mfeAuthProvider.getAccessToken()
    const headers = {
      accept: 'application/json',
      authorization: `Bearer ${stratusToken}`
    }

    return this.httpClient
      .get(`${this.baseURL}/subscriptions`, {
        headers
      })
      .then(({ data }) =>
        data?.contents ? this.splitHybridSubscription(data.contents) : []
      )
      .catch((err) => {
        if (err?.response?.status === 403) {
          forceLoginHandling()
        }
        sendOpenTelemetryEvent(
          'Error while fetching subscription data from SubscriptionStateClient: ' +
            JSON.stringify(err)
        )
        throw err
      })
  }

  async retrieveDeviceCaches(): Promise<DeviceCache[]> {
    const params: JarvisWebHttpClientRequestOptions = {
      params: { types: 'all' }
    }
    return this.deviceCacheApiClient
      .list(params)
      .then(({ data }) => data)
      .catch((err) => {
        sendOpenTelemetryEvent(
          `Error while fetching  data from retrieve device caches api : ` +
            JSON.stringify(err)
        )
        throw err
      })
  }
}
