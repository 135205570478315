import { ShippingAddress } from './ShippingAddress'

export type SubscriptionEntity = {
  entityId: string
  subscriptionId?: string
  userId: string
  shippingAddressId: string
  state: string
  entityType: string
  entityStartDate?: string
  entityEndDate?: string
  linkedEntityIds?: Array<string>
  product: EntityProduct
  source: EntitySource
  entityDetails?: EntityDetails
  deviceInfo?: Device | AddOn
  shippingAddress?: ShippingAddress
  commerce?: Commerce
  linkedEntities?: Array<LinkedEntity>
}

type LinkedEntity = {
  type: string
  value: string
}
type EntityProduct = {
  type: string
  value?: Record<string, unknown>
}
type EntitySource = {
  type: string
  value?: Record<string, unknown>
}

type EntityDetails = {
  accessoryId?: string
  serialNumber?: string
  uniqueDeviceId?: string
  pendingAssociationId?: string
  pendingAssociationState?: string
}

type Commerce = {
  type?: string
  value?: CommerceValue
}

type CommerceValue = {
  subscriptionId?: string
  friendlySubscriptionId?: string
}

export type Device = {
  name: string
  friendlyName?: string
  image?: string
  productNumber: string
  serialNumber?: string
  eligibleForRefresh?: string
  type: string
}

export type AddOn = {
  name: string
  image?: string
  friendlyName?: string
  type: string
}

export enum mblEntityType {
  chromebook = 'chromebook',
  pc = 'iot-pc',
  printer = 'iot-printer',
  keyboard = 'keyboard',
  mouse = 'mouse',
  pen = 'pen',
  paper = 'paper',
  software = 'software',
  carepack = 'carepack'
}
