import { useContext, useEffect } from 'react'
import { WarningContext } from '@/context/WarningContext'
import { WarningContextType, WarningType } from 'src/types/Warning'

export default function useWarningType(receivedWarningType: WarningType) {
  const { warningType, setWarningType } = useContext<
    Partial<WarningContextType>
  >(WarningContext)
  useEffect(() => {
    if (
      (warningType === 'shippingWarning' &&
        receivedWarningType === 'billingWarning') ||
      (warningType === 'billingWarning' &&
        receivedWarningType === 'shippingWarning')
    ) {
      setWarningType('shippingAndBillingWarning')
    } else if (
      warningType === 'noWarning' &&
      receivedWarningType === 'billingWarning'
    ) {
      setWarningType('billingWarning')
    } else if (
      warningType === 'noWarning' &&
      receivedWarningType === 'shippingWarning'
    ) {
      setWarningType('shippingWarning')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
