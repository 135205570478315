export interface ForceLoginAttemptsInfo {
  attempts?: number
  lastAttempt?: Date
}

export const saveLoginAttempts = (totalAttempts): void => {
  const forceLoginAttemptsInfo = {
    attempts: totalAttempts,
    lastAttempt: new Date()
  }
  localStorage.setItem(
    'forceLoginAttempts',
    JSON.stringify(forceLoginAttemptsInfo)
  )
}
export const getLoginAttempts = (): ForceLoginAttemptsInfo | null =>
  JSON.parse(localStorage.getItem('forceLoginAttempts'))
