import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  usePaymentMethodInfo,
  useDispatch,
  useGetText,
  useLocale,
  useOnSave,
  usePaymentType,
  useBillingFormState,
  usePaymentMethodSettings
} from '../../hooks'
import {
  popCurrentStepAction,
  pickupPHCTokenAndSyncAction
} from '../../actions'
import { Spinner } from '../Spinner'
import { BackButton } from '../BackButton'
import { StyledPgsContainer, StyledPgsForm } from './styles'
import {
  StyledHeader,
  StyledStepTitle,
  StyledStepNumber
} from '../Shared/styles'
import Button from '@veneer/core/dist/scripts/button'

export const PgsForm = () => {
  const getText = useGetText()
  const paymentType = usePaymentType()
  const paymentMethodInfo = usePaymentMethodInfo()
  const paymentMethodSettings = usePaymentMethodSettings()
  const {
    firstName,
    lastName,
    address,
    address2,
    city,
    state,
    postalCode,
    countryCode
  } = paymentMethodInfo?.billingAddress || {}
  const { tenantId } = paymentMethodInfo || {}
  const { merchantName, paymentTypeSettings } = paymentMethodSettings || {}
  const { virtualKeyboard, subscriptionId } = useBillingFormState()
  const dispatch = useDispatch()
  const analyticsEventRef = useRef<HTMLButtonElement>(null)
  const onSave = useOnSave()
  const locale = useLocale()
  const [initForm, setInitForm] = useState(true)
  const [iframeLoaded, setIframeLoaded] = useState(false)
  const formRef = useRef<HTMLFormElement>(null)
  const iFrameRef = useRef<HTMLIFrameElement>(null)
  const iFrameId = 'pgs-iframe'
  const pgsIframeUrl = paymentTypeSettings?.credit_card?.url //TODO: dynamic payment type?
  const iFrameUrl = pgsIframeUrl?.replace(/\/$/, '')
  const onBack = useCallback(() => dispatch(popCurrentStepAction()), [dispatch])

  useEffect(() => {
    const postMessageToIframe = (message: string) => {
      const iFrameWindow = iFrameRef.current?.contentWindow
      iFrameWindow?.postMessage(message, '*')
    }
    const reloadIframe = async () => {
      try {
        setInitForm(true)
      } catch {
        // do nothing
      }
    }
    const iframeListener = async (event: MessageEvent) => {
      if (event.data.msg === 'handlePgsIframeSuccess') {
        try {
          await dispatch(pickupPHCTokenAndSyncAction(event.data.pickupId))

          analyticsEventRef.current?.click()
          postMessageToIframe('displayPgsFormSuccess')
          onSave()

          return
        } catch {
          // do nothing
        }
        postMessageToIframe('displayPgsFormFailure')
        setTimeout(reloadIframe, 5000)
      }
    }
    window.addEventListener('message', iframeListener)
    return () => {
      window.removeEventListener('message', iframeListener)
    }
  }, [dispatch, onSave])

  useEffect(() => {
    if (initForm && formRef.current) {
      formRef.current.submit()
      setInitForm(false)
    }
  }, [initForm, formRef])

  const renderForm = () => {
    if (initForm) {
      const zip = postalCode ? postalCode.toUpperCase() : postalCode
      const paymentInfo = {
        CC: { IsServiceRequired: true, IsVK: Boolean(virtualKeyboard) }
      }
      const phcRequest = JSON.stringify({
        ...paymentInfo,
        MerchantName: merchantName,
        CustomerInfo: {
          FirstName: firstName,
          LastName: lastName
        },
        BillAddress: {
          Address1: address,
          Address2: address2,
          City: city,
          State: state,
          Zip: zip,
          Country: countryCode
        },
        OrderID: subscriptionId,
        CustomerID: tenantId,
        Culture: locale.replace('_', '-')
      })
      return (
        <form
          ref={formRef}
          method="post"
          id="postToIframe"
          action={iFrameUrl}
          target={iFrameId}
        >
          <input type="hidden" name="phcRequest" value={phcRequest} />
        </form>
      )
    }
    return null
  }

  return (
    <>
      <BackButton onClick={onBack} />
      <StyledHeader>
        <StyledStepNumber>
          {getText('billing_form.step_title.2')}:{' '}
        </StyledStepNumber>
        <StyledStepTitle>
          {getText(`${paymentType}_form.sub_title`)}
        </StyledStepTitle>
      </StyledHeader>
      {iframeLoaded ? null : <Spinner />}
      <StyledPgsContainer show={iframeLoaded}>
        <StyledPgsForm
          id={iFrameId}
          title={iFrameId}
          key={iFrameId}
          ref={iFrameRef}
          name={iFrameId}
          onLoad={() => setIframeLoaded(true)}
        />
        {renderForm()}
      </StyledPgsContainer>
      <Button
        data-analyticsid="SaveButton"
        style={{ display: 'none' }}
        ref={analyticsEventRef}
        onClick={(event) => {
          event.persist()
        }}
      />
    </>
  )
}
