import React from 'react'
import LDProvider from 'launchdarkly-react-client-sdk/lib/provider'

export default function LaunchDarkly({ children }) {
  const launchDarklyClientId = `${process.env.NEXT_PUBLIC_LAUNCHDARKLY_CLIENTSIDE_ID}`

  return (
    <LDProvider
      clientSideID={launchDarklyClientId}
      options={{
        bootstrap: 'localStorage',
        streaming: false
      }}
      reactOptions={{
        useCamelCaseFlagKeys: true
      }}
    >
      <div data-testid="LaunchDarkly">{children}</div>
    </LDProvider>
  )
}
