import { AxiosResponse } from 'axios'

export class MockService {
  defaultDelay: number

  constructor(defaultDelay = 2000) {
    this.defaultDelay = defaultDelay
  }

  protected resolve(
    result: AxiosResponse,
    delay: number = this.defaultDelay
  ): Promise<AxiosResponse> {
    return new Promise((resolve: (result: AxiosResponse) => void) => {
      setTimeout(() => resolve(result), delay)
    })
  }
}
