import React from 'react'
import { useBillingFormState, useContainerSize } from '../../hooks'
import { BillingFormContent } from './billingFormContent'
import { ThemeProvider } from 'styled-components'
import { StyledBillingForm } from './styles'

export const BillingFormContainer = () => {
  const containerSize = useContainerSize()
  const { trackClickEvent } = useBillingFormState()

  return (
    <ThemeProvider theme={{ containerSize }}>
      <StyledBillingForm
        onClick={(event) => {
          try {
            const linkId = (event.target as Element).getAttribute(
              'data-analyticsid'
            )
            if (linkId) {
              trackClickEvent?.call(null, linkId, 'payment-information')
            }
          } catch {
            // do nothing
          }
        }}
      >
        <BillingFormContent />
      </StyledBillingForm>
    </ThemeProvider>
  )
}
