import SubscriptionItem from '@/components/SubscriptionItem'
import getAuthProvider from '@/helpers/getAuthProvider'
import getLocalization from '@/helpers/getLocalization'
import useGetText from '@/hooks/useGetText'
import useShellContext from '@/hooks/useShellContext'
import { SubscriptionType } from '@/types/SubscriptionType'
import getSubscriptionTitleTextId from '@/helpers/getSubscriptionTitleTextId'
import { BillingForm } from '@jarvis/react-paas-billing'
import Card from '@veneer/core/dist/scripts/card'
import Modal from '@veneer/core/dist/scripts/modal'
import React, { useState } from 'react'
import {
  BillingItemContainer,
  BillingSectionContainer,
  ModalContentContainer,
  StyledBillingDetailsContainer,
  StyledBillingTileContainer,
  StyledEditButtonContainer
} from './styles'
import HpOneCreditCard from '../HpOneCreditCard'
import { useAppContext } from '@/context/AppContext'
import useCookie from '@/hooks/useCookie/useCookies'
import {
  BillingModalWindowCancelBtnClicked,
  BillingModalWindowContinueBtnClicked,
  BillingModalWindowDisplayed,
  EditPaymentLinkClicked,
  publishEvent
} from '@/utils/analytics'
import getBorderStyle from '@/helpers/getBorderStyle'
import { setBillingForm, setInlineNotification } from '@/actions/Actions'
import { EDelinquencyNotification } from '@/types/pendingChanges'
import usePaymentDetails from '@/hooks/usePaymentDetails/usePaymentDetails'
export type SubscriptionItemProps = {
  expirationStatus?: EDelinquencyNotification
  isDisabled?: boolean
}
const HpOnePaymentMethod = ({
  expirationStatus,
  isDisabled
}: SubscriptionItemProps) => {
  const { state, dispatch } = useAppContext()
  const getText = useGetText('billing')
  const getTextSub = useGetText('subscriptions')
  const title = getTextSub(getSubscriptionTitleTextId(SubscriptionType.HP_ONE))
  const shell = useShellContext()
  const authProvider = getAuthProvider()
  const { subscriptionInfo, account } = useAppContext().state
  const { paymentMethodDetails, forceRefresh } = usePaymentDetails()
  const { language } = getLocalization()
  const isAgentSession = useCookie('stratus-session-id')
  const country = account ? account.data.regionId : process.env.DEFAULT_COUNTRY
  const currLanguage = language ? language : process.env.DEFAULT_LANGUAGE

  const [
    currentBillingFormModalStep,
    setCurrentBillingFormModalStep
  ] = useState<number>(1)

  const subscriptionFriendlyIds = subscriptionInfo?.data?.map(
    (subscription) => subscription.friendlySubscriptionId
  )
  const subscriptionId = subscriptionInfo?.data?.map(
    (subscription) => subscription.subscriptionId
  )

  const editBillingClick = () => {
    dispatch(setBillingForm(!state.billingForm.isModalOpen))
    publishEvent(
      { ...EditPaymentLinkClicked, screenMode: 'HpOne' },
      { actionParams: { subscriptionId: subscriptionFriendlyIds.join('+') } }
    )
    publishEvent({
      ...BillingModalWindowDisplayed,
      screenName: `Billing1`,
      screenMode: 'HpOne'
    })
  }

  const handleOnCloseModal = () => {
    dispatch(setBillingForm(false))
    publishEvent({
      ...BillingModalWindowCancelBtnClicked,
      screenName: `Billing${currentBillingFormModalStep}`,
      screenMode: 'HpOne'
    })
    setCurrentBillingFormModalStep(1)
  }

  const handleOnUpdate = () => {
    publishEvent({
      ...BillingModalWindowContinueBtnClicked,
      screenName: `Billing1`,
      screenMode: 'HpOne'
    })
    publishEvent({
      ...BillingModalWindowDisplayed,
      screenName: `Billing2`,
      screenMode: 'HpOne'
    })
    setCurrentBillingFormModalStep(2)
  }

  const handleOnSave = () => {
    dispatch(setBillingForm(false))
    publishEvent({
      ...BillingModalWindowContinueBtnClicked,
      screenName: `Billing2`,
      screenMode: 'HpOne'
    })
    dispatch(
      setInlineNotification({
        display: true,
        title: getText('billingSavedSuccess.hpAllInPlanTitle'),
        status: 'positive'
      })
    )
    forceRefresh()
  }
  const cardBoxBorder = getBorderStyle(expirationStatus)

  const paymentMethodSubscriptionContent = subscriptionInfo &&
    subscriptionFriendlyIds && (
      <SubscriptionItem
        subscriptionType={SubscriptionType.HP_ONE}
        subscriptionIds={subscriptionFriendlyIds}
        title={title}
        isDisabled={isDisabled}
      />
    )
  const hpOnePaymentMethodDetailsContent = (
    <>
      {subscriptionInfo?.data?.map((subscription) => (
        <StyledBillingTileContainer key={subscription?.id}>
          {paymentMethodSubscriptionContent}
          <StyledBillingDetailsContainer>
            <HpOneCreditCard
              billingInfo={paymentMethodDetails}
              t={getText}
              expirationStatus={expirationStatus}
            />
            <StyledEditButtonContainer
              data-testid="editPaymentBillingButton"
              isDisabled={isDisabled}
              aria-disabled={isDisabled}
            >
              <a
                role="button"
                data-testid="editbillingButton"
                onClick={editBillingClick}
                id="manage-payment-method"
              >
                {getText('editPayment')}
              </a>
            </StyledEditButtonContainer>
          </StyledBillingDetailsContainer>
        </StyledBillingTileContainer>
      ))}
    </>
  )

  const hpOnePaymentMethodTileContent = (
    <BillingSectionContainer data-testid="hpOnePaymentMethodTileContent">
      {hpOnePaymentMethodDetailsContent}

      <Modal
        closeButton
        show={state.billingForm.isModalOpen}
        onClose={handleOnCloseModal}
      >
        {state.billingForm.isModalOpen ? (
          <ModalContentContainer>
            <BillingForm
              subscriptionId={subscriptionId[0]}
              accountIdentifier={'testingId'}
              country={country}
              language={currLanguage}
              authProvider={authProvider}
              onSave={handleOnSave}
              onCancel={handleOnCloseModal}
              onUpdate={handleOnUpdate}
              stack={shell?.stack}
              virtualKeyboard={isAgentSession}
              nativeApp={shell?.isNative}
              mockStratus={false}
            />
          </ModalContentContainer>
        ) : (
          <></>
        )}
      </Modal>
    </BillingSectionContainer>
  )

  return (
    <BillingItemContainer isDisabled={isDisabled}>
      <Card
        content={hpOnePaymentMethodTileContent}
        className="card-box"
        customStyle={{ borderRadius: 8, border: `${cardBoxBorder}` }}
      />
    </BillingItemContainer>
  )
}

export default HpOnePaymentMethod
