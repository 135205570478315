import useAccountClient from '../useAccountClient'
import useDataFetcher from '../useDataFetcher'

const useAccount = () => {
  const accountClient = useAccountClient()
  return useDataFetcher(
    'account',
    accountClient?.getAccountData
      ? accountClient.getAccountData.bind(accountClient)
      : null
  )
}

export default useAccount
