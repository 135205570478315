import { useEffect, useState } from 'react'
import PaymentMethodClient from '../../services/PaymentMethodClient'
import getAuthProvider from '@/helpers/getAuthProvider'

export default function usePaymentMethodClient() {
  const [paymentMethodClient, setPaymentMethodClient] = useState(null)
  const authProvider = getAuthProvider()
  useEffect(() => {
    setPaymentMethodClient(new PaymentMethodClient(authProvider))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return paymentMethodClient
}
