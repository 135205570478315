import React from 'react'
import getPaymentCardInfo from '@/helpers/getPaymentCardInfo'
import { StyledCCImageContainer, StyledDetailsInfo } from './styles'

const CreditCard = ({ userName, billingInfo, t }) => {
  const creditCardInfo = getPaymentCardInfo(billingInfo?.creditCard)
  return (
    <>
      <StyledCCImageContainer>
        <img
          src={creditCardInfo?.cardImage}
          alt={billingInfo?.creditCard?.cardTypeKey}
        />
      </StyledCCImageContainer>
      <StyledDetailsInfo>{userName}</StyledDetailsInfo>
      <StyledDetailsInfo>
        {billingInfo?.creditCard?.cardNumber}
      </StyledDetailsInfo>
      <StyledDetailsInfo>
        <>
          {t('expires')} {billingInfo?.creditCard?.displayExpirationDate}
        </>
      </StyledDetailsInfo>
    </>
  )
}

export default CreditCard
