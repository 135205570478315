import styled, { css } from 'styled-components'

export const StyledBackButton = styled.div`
  position: absolute;
  top: 0;
  left: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;

  ${(props) => {
    const tabPortMobile = css`
      top: 0;
      left: 0;
      transform: scale(0.8);
      transform-origin: top left;

      > button {
        padding: 0;
      }
    `
    const responsive = {
      'mobile-container': tabPortMobile,
      'tablet-portrait-container': tabPortMobile
    }
    return responsive[props.theme.containerSize]
  }}
`

export const StyledButtonContent = styled.div`
  display: flex;
  align-items: center;

  > span {
    margin-left: 8px;
  }
`
