import React, { useEffect, useMemo, useRef } from 'react'
import { setFallbackLocale } from '../../lib/localeHelpers'
import {
  DESKTOP_CONTAINER,
  MOBILE_CONTAINER,
  TABLET_LANDSCAPE_CONTAINER,
  TABLET_PORTRAIT_CONTAINER,
  useAsyncReducer
} from '../../hooks'
import {
  billingFormInitialState,
  billingFormReducer
} from '../../reducers/billingFormReducer'
import { setAssetsProviderAction, setContainerSizeAction } from '../../actions'
import {
  MOBILE_MAX,
  TABLET_LANDSCAPE_MAX,
  TABLET_PORTRAIT_MAX
} from '../../lib/windowSize'
import { BillingFormContext } from '../../contexts/billingFormContext'
import { BillingFormProps } from './billingForm'

export const BillingFormProvider: React.FC<BillingFormProps> = ({
  children,
  ...props
}) => {
  const { language, country } = useMemo(
    () => setFallbackLocale(props.language, props.country),
    [props.language, props.country]
  )
  const [billingFormState, dispatch] = useAsyncReducer(
    billingFormReducer,
    billingFormInitialState({ ...props, country, language })
  )
  const billingFormRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    ;(async () => {
      await dispatch(setAssetsProviderAction(language, country))
    })()
  }, [language, country, dispatch])

  useEffect(() => {
    if (billingFormRef.current) {
      const recalculateContainerSize = () => {
        if (billingFormRef.current) {
          const element = billingFormRef.current
          const newContainerSize =
            element.clientWidth <= MOBILE_MAX
              ? MOBILE_CONTAINER
              : element.clientWidth <= TABLET_PORTRAIT_MAX
              ? TABLET_PORTRAIT_CONTAINER
              : element.clientWidth <= TABLET_LANDSCAPE_MAX
              ? TABLET_LANDSCAPE_CONTAINER
              : DESKTOP_CONTAINER
          dispatch(setContainerSizeAction(newContainerSize)).then()
        }
      }

      recalculateContainerSize()
      window.addEventListener('resize', recalculateContainerSize)

      return () => {
        window.removeEventListener('resize', recalculateContainerSize)
      }
    }
  }, [dispatch])

  return (
    <div ref={billingFormRef}>
      <BillingFormContext.Provider value={{ billingFormState, dispatch }}>
        {children}
      </BillingFormContext.Provider>
    </div>
  )
}
