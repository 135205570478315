import { SubscriptionEntity } from 'src/types/SubscriptionEntity'

export const getReplacedEntity = (
  entities: SubscriptionEntity[]
): SubscriptionEntity | null =>
  entities.reduce((entityAcc, entity) => {
    if (
      (entity.state === 'deactivating' || entity.state === 'inactive') &&
      entity.linkedEntities.find(
        (linkedEntity) =>
          linkedEntity.type === 'replacedBy' ||
          linkedEntity.type === 'replacedWith'
      )
    ) {
      const replaced = entity.linkedEntities.find(
        (item) =>
          item.type === 'replacedBy' &&
          !entity.linkedEntities.some(
            (innerLinkedEntity) => innerLinkedEntity.type === 'replacedWith'
          )
      )
      if (replaced) {
        entityAcc = entity
      }
    } else {
      entityAcc = entity
    }
    return entityAcc
  }, null)
