import React from 'react'
import Images from '@/assets/images'
import { StyledCardHolder, StyledDetailsInfo, StyledPayPalLogo } from './styles'

const PayPal = ({ userName, billingInfo }) => {
  return (
    <>
      <StyledPayPalLogo>
        <img src={Images.paypalTextWithLogo} alt={`Pay Pal`} />
      </StyledPayPalLogo>
      <StyledCardHolder>{userName}</StyledCardHolder>
      <StyledDetailsInfo>{billingInfo?.payPal?.email}</StyledDetailsInfo>
    </>
  )
}

export default PayPal
