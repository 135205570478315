import styled from 'styled-components'
import tokens from '@veneer/tokens'

export const BillingSectionContainer = styled.div`
  margin: 24px 24px;
  font-family: ${tokens.typography.family0};
  font-weight: 400;
`

export const StyledIconContainer = styled.div`
  && {
    display: flex;
    padding: 0 0 0 0;
    img {
      width: 32px;
    }
  }
`

export const StyledTextContainer = styled.div`
  && {
    color: ${tokens.color.gray9};
    padding: 12px 0 0 0;
    letter-spacing: 0.02em;
  }
`

export const StyledTitle = styled.div`
  && {
    font-family: ${tokens.typography.family0};
    color: ${tokens.color.gray9};
    font-size: ${tokens.typography.size5};
    line-height: ${tokens.typography.lineHeight5};
    padding: 24px 0 0 0;
  }
`
