import { AxiosResponse } from 'axios'
import { PaymentMethodInfo, PaymentMethodSettings } from '../types'
import { StratusClient } from './stratusClient'
import { BaseURLProvider, Stack } from '@jarvis/web-stratus-client'
import { JarvisAuthProvider } from '@jarvis/web-http'

export interface IPaymentControllerService {
  getPaymentMethodSettings(
    country: string
  ): Promise<AxiosResponse<PaymentMethodSettings>>

  getPaymentMethodInfo(
    subscriptionId: string
  ): Promise<AxiosResponse<PaymentMethodInfo>>

  updateBillingAddress(
    subscriptionId: string,
    paymentMethodId: string,
    billingAddressId: string
  ): Promise<AxiosResponse<void>>

  syncPaymentMethodAndAddress(
    subscriptionId: string,
    paymentMethodId: string,
    billingAddressId: string
  ): Promise<AxiosResponse<void>>
}

function getBaseURLProvider(baseURL: BaseURLProvider | string | Stack) {
  if (['function', 'string'].includes(typeof baseURL)) {
    return baseURL
  }

  const defaultBaseURL = [
    'https://dev-us1.api.ws-hp.com',
    'https://pie-us1.api.ws-hp.com',
    'https://stage-us1.api.ws-hp.com',
    'https://us1.api.ws-hp.com'
  ][baseURL as Stack]
  return async (apiName: string, apiVersion: string) =>
    `${defaultBaseURL}/${apiName}/v${apiVersion}`
}

export class PaymentControllerService
  extends StratusClient
  implements IPaymentControllerService
{
  apiName = 'payment-controller'
  apiVersion = '1'
  settingsEndpoint = '/paymentmethodsettings'
  infoEndpoint = '/paymentmethodinfo'

  constructor(
    baseURL: BaseURLProvider | string | Stack,
    authProvider: JarvisAuthProvider,
    xCorrelationId?: string
  ) {
    super(getBaseURLProvider(baseURL), authProvider, xCorrelationId)
  }

  getPaymentMethodInfo(
    subscriptionId: string
  ): Promise<AxiosResponse<PaymentMethodInfo>> {
    return this.jarvisWebHttpInstance.get({
      headers: this.defaultHeaders(),
      url: `${this.infoEndpoint}/${subscriptionId}`
    })
  }

  getPaymentMethodSettings(
    country: string
  ): Promise<AxiosResponse<PaymentMethodSettings>> {
    return this.jarvisWebHttpInstance.get({
      headers: this.defaultHeaders(),
      url: `${this.settingsEndpoint}/${country}`
    })
  }

  updateBillingAddress(
    subscriptionId: string,
    paymentMethodId: string,
    billingAddressId: string
  ): Promise<AxiosResponse<void>> {
    return this.jarvisWebHttpInstance.put({
      headers: this.defaultHeaders(),
      url: `${this.infoEndpoint}/${paymentMethodId}/billingAddress`,
      data: {
        billingAddressId,
        subscriptionId
      }
    })
  }

  syncPaymentMethodAndAddress(
    subscriptionId: string,
    paymentMethodId: string,
    billingAddressId: string
  ): Promise<AxiosResponse<void>> {
    return this.jarvisWebHttpInstance.put({
      headers: this.defaultHeaders(),
      url: `${this.infoEndpoint}/${paymentMethodId}`,
      data: {
        billingAddressId,
        subscriptionId
      }
    })
  }
}
