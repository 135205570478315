import { trace } from '@opentelemetry/api'

const sendOpenTelemetryEvent = (message: string) => {
  const span = trace
    .getTracer('shipping-billing')
    .startSpan('shipping-billing', {
      attributes: {
        'workflow.name': 'monetization/shipping-billing.error',
        error: true,
        'error.message': message
      }
    })
  span.end()
}

export default sendOpenTelemetryEvent
