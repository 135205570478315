import React, { FC } from 'react'
import { useGetText } from '../../hooks'
import { AddressForm } from './addressForm'
import { StyledSubtitle } from './styles'

type AddressSectionProps = {
  showStateDropdown: boolean
}

export const AddressSection: FC<AddressSectionProps> = ({
  showStateDropdown
}) => {
  const getText = useGetText()

  return (
    <form>
      <StyledSubtitle>{getText('billing_address.title')}</StyledSubtitle>
      <AddressForm showStateDropdown={showStateDropdown} />
    </form>
  )
}
