import {
  BillingAddress,
  BillingFlowSteps,
  BillingFormAction,
  BillingFormActionType,
  BillingFormState,
  DataType,
  PaymentType
} from '../types'
import { AsyncAction, AsyncDispatch } from '../hooks'
import { AxiosResponse } from 'axios'
import equal from 'fast-deep-equal'
import { IAddressService } from '../lib/addressService'
import { IPaymentControllerService } from '../lib/paymentControllerService'

const {
  FETCH_PAYMENT_METHOD_SETTINGS,
  FETCH_PAYMENT_METHOD_SETTINGS_SUCCESS,
  FETCH_PAYMENT_METHOD_SETTINGS_FAIL,
  FETCH_PAYMENT_METHOD_INFO,
  FETCH_PAYMENT_METHOD_INFO_SUCCESS,
  FETCH_PAYMENT_METHOD_INFO_FAIL,
  SET_ASSETS_PROVIDER,
  SET_PAYMENT_TYPE,
  SET_CONTAINTER_SIZE,
  PUSH_CURRENT_STEP,
  POP_CURRENT_STEP,
  SAVE_BILLING_ADDRESS_SUCCESS,
  SAVE_BILLING_ADDRESS_FAIL,
  PICKUP_PHC_TOKEN_AND_SYNC_SUCCESS,
  PICKUP_PHC_TOKEN_AND_SYNC_FAIL,
  SET_SAVED_BILLING_ADDRESS,
  SET_PAYMENT_METHOD_BILLING_ADDRESS
} = BillingFormActionType

export const setAssetsProviderAction = (
  language: string,
  country: string
): BillingFormAction => ({
  type: SET_ASSETS_PROVIDER,
  language,
  country
})

export const setPaymentTypeAction = (
  paymentType: PaymentType
): BillingFormAction => ({
  type: SET_PAYMENT_TYPE,
  paymentType
})

export const setContainerSizeAction = (containerSize: string) => ({
  type: SET_CONTAINTER_SIZE,
  containerSize
})

export const pushCurrentStepAction = (currentStep: BillingFlowSteps) => ({
  type: PUSH_CURRENT_STEP,
  currentStep
})

export const popCurrentStepAction = () => ({
  type: POP_CURRENT_STEP
})

export const validateBillingAddressAction = ({
  field,
  value,
  requiredFields
}: {
  field?: string
  value?: string
  requiredFields?: string[]
}) => ({
  type: BillingFormActionType.VALIDATE_BILLING_ADDRESS,
  field,
  value,
  requiredFields
})

function createAsyncAction<T>(
  action: BillingFormActionType,
  successAction: BillingFormActionType,
  failAction: BillingFormActionType,
  getResponse: (state: BillingFormState) => Promise<AxiosResponse<T>>
): AsyncAction<BillingFormAction, BillingFormState, T> {
  return async (
    dispatch: AsyncDispatch<BillingFormAction, BillingFormState, T>,
    getState: () => BillingFormState
  ) => {
    try {
      await dispatch({ type: action })
      const response = await getResponse(getState())
      await dispatch({ type: successAction, response })
      return response.data
    } catch (error) {
      await dispatch({ type: failAction, error })
      throw error
    }
  }
}

export const fetchPaymentMethodSettingsAction = () =>
  createAsyncAction(
    FETCH_PAYMENT_METHOD_SETTINGS,
    FETCH_PAYMENT_METHOD_SETTINGS_SUCCESS,
    FETCH_PAYMENT_METHOD_SETTINGS_FAIL,
    ({ paymentControllerService, country }) =>
      paymentControllerService.getPaymentMethodSettings(country)
  )

export const fetchPaymentMethodInfoAction = () =>
  createAsyncAction(
    FETCH_PAYMENT_METHOD_INFO,
    FETCH_PAYMENT_METHOD_INFO_SUCCESS,
    FETCH_PAYMENT_METHOD_INFO_FAIL,
    ({ paymentControllerService, subscriptionId }) =>
      paymentControllerService.getPaymentMethodInfo(subscriptionId)
  )

export const pickupPHCTokenAndSyncAction =
  (pickupId: string) =>
  async (
    dispatch: AsyncDispatch<BillingFormAction, BillingFormState, void>,
    getState: () => BillingFormState
  ) => {
    try {
      const {
        subscriptionId,
        paymentControllerService,
        paymentMethodService,
        paymentMethodSettings,
        country,
        paymentMethodInfo
      } = getState()
      if (paymentMethodSettings && paymentMethodInfo) {
        const response = await paymentMethodService.pickupPHCToken(
          paymentMethodSettings.businessUnitGuid,
          country,
          {
            billing_address_id: paymentMethodInfo.billingAddress.resourceId,
            tenant_id: paymentMethodInfo.tenantId,
            pickup_token_id: pickupId
          }
        )
        await paymentControllerService.syncPaymentMethodAndAddress(
          subscriptionId,
          response.data.id,
          paymentMethodInfo.billingAddress.resourceId
        )
        await dispatch({ type: PICKUP_PHC_TOKEN_AND_SYNC_SUCCESS })
      }
    } catch (error) {
      await dispatch({ type: PICKUP_PHC_TOKEN_AND_SYNC_FAIL, error })
      throw error
    }
  }

export const saveBillingAddressAction =
  () =>
  async (
    dispatch: AsyncDispatch<BillingFormAction, BillingFormState, void>,
    getState: () => BillingFormState
  ) => {
    try {
      const {
        subscriptionId,
        paymentMethodInfo,
        billingAddress,
        addressService,
        paymentControllerService,
        onUpdate
      } = getState()

      if (
        paymentMethodInfo &&
        billingAddress.current &&
        billingAddress.original
      ) {
        const referenceAddress = billingAddress.saved || billingAddress.original

        if (!equal(billingAddress.current, referenceAddress)) {
          if (billingAddress.saved) {
            await updateSavedStratusAddress(
              addressService,
              billingAddress.current,
              dispatch
            )
          } else {
            const response = await createStratusAddress(
              addressService,
              billingAddress.current,
              dispatch
            )
            await updatePaymentMethodBillingAddress(
              paymentControllerService,
              subscriptionId,
              paymentMethodInfo.pmsId,
              response.data,
              dispatch
            )
          }
          onUpdate?.call(null, DataType.billingAddress)
        } else if (
          billingAddress.saved &&
          paymentMethodInfo.billingAddress.resourceId !=
            billingAddress.saved.resourceId
        ) {
          await updatePaymentMethodBillingAddress(
            paymentControllerService,
            subscriptionId,
            paymentMethodInfo.pmsId,
            billingAddress.saved,
            dispatch
          )
          onUpdate?.call(null, DataType.billingAddress)
        }
        await dispatch({
          type: SAVE_BILLING_ADDRESS_SUCCESS
        })
      }
    } catch (error) {
      await dispatch({
        type: SAVE_BILLING_ADDRESS_FAIL,
        error
      })
      throw error
    }
  }

const createStratusAddress = async (
  addressService: IAddressService,
  newAddress: BillingAddress,
  dispatch: AsyncDispatch<BillingFormAction, BillingFormState, void>
): Promise<AxiosResponse<BillingAddress>> => {
  const response = await addressService.createAddress(newAddress)
  await dispatch({
    type: SET_SAVED_BILLING_ADDRESS,
    address: response.data
  })
  return response
}

const updatePaymentMethodBillingAddress = async (
  paymentControllerService: IPaymentControllerService,
  subscriptionId: string,
  pmsId: string,
  newAddress: BillingAddress,
  dispatch: AsyncDispatch<BillingFormAction, BillingFormState, void>
) => {
  await paymentControllerService.updateBillingAddress(
    subscriptionId,
    pmsId,
    newAddress.resourceId
  )
  await dispatch({
    type: SET_PAYMENT_METHOD_BILLING_ADDRESS,
    address: newAddress
  })
}

const updateSavedStratusAddress = async (
  addressService: IAddressService,
  newAddress: BillingAddress,
  dispatch: AsyncDispatch<BillingFormAction, BillingFormState, void>
) => {
  const response = await addressService.updateAddress(newAddress)
  await dispatch({
    type: SET_SAVED_BILLING_ADDRESS,
    address: response.data
  })
}
