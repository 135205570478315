import { useState, useEffect } from 'react'
import ShippingClient from '../../services/ShippingClient'
import useShellContext from '../useShellContext'
import getAuthProvider from '@/helpers/getAuthProvider'

export default function useShippingClient() {
  const [shippingClient, setShippingClient] = useState(null)
  const shellContext = useShellContext()
  const authProvider = getAuthProvider()

  useEffect(() => {
    setShippingClient(
      new ShippingClient(authProvider, shellContext?.xCorrelationId?.toString())
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return shippingClient
}
