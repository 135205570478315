import { postcodeValidator } from 'postcode-validator'

export class AddressValidator {
  country: string

  constructor(country: string) {
    this.country = country
  }

  validateField(field: string, value?: string) {
    let valid = false

    switch (field) {
      case 'firstName':
      case 'lastName':
        valid = this.validateName(value)
        break
      case 'company':
        valid = this.validateCompany(value)
        break
      case 'address':
        valid = this.validateStreet(value)
        break
      case 'address2':
        valid = !value || this.validateStreet(value)
        break
      case 'city':
        valid = this.validateCity(value)
        break
      case 'state':
        valid = this.validateState(value)
        break
      case 'postalCode':
        valid = this.validateZipCode(value)
        break
    }

    return valid && !this.containsCreditCardInfo(value)
  }

  private containsCreditCardInfo(string?: string): boolean {
    const value = string ? string.trim() : ''
    return (
      value.length > 12 &&
      !!value
        .replace(/\D+/g, ',')
        .split(',')
        .find((token) => this.isCreditCard(token))
    )
  }

  private isCreditCard(string: string): boolean {
    return /^[2-6]/.test(string) && [13, 15, 16].includes(string.length)
  }

  private validateName(string?: string): boolean {
    const value = string ? string.trim() : ''
    return (
      value.length >= 1 &&
      value.length <= 30 &&
      /^[^`~_!@#$%^*()+=[\]\\/;{}|:<>?0-9]+$/i.test(value)
    )
  }

  private validateCompany(string?: string) {
    const value = string ? string.trim() : ''
    const valid =
      value.length <= 50 && /^[^`~_!@#$%^*()+=[\]\\/;{}|:<>?]+$/i.test(value)

    return value.length === 0 || valid
  }

  private validateStreet(string?: string) {
    const value = string ? string.trim() : ''
    return (
      value.length >= 1 &&
      value.length <= 30 &&
      /^[^~_!@$%^*\\+=[\];{}|<>?]+$/i.test(value)
    )
  }

  private validateCity(string?: string) {
    const value = string ? string.trim() : ''
    const match =
      this.country === 'IE'
        ? /^[^`~_!@#$%^*()+=[\]\\/;{}|:<>?]+$/i.test(value)
        : /^[^`~_!@#$%^*()+=[\]\\/;{}|:<>?0-9]+$/i.test(value)

    return value.length >= 2 && value.length <= 50 && match
  }

  private validateState(string?: string) {
    const value = string ? string.trim() : ''
    return value.length > 0
  }

  private validateGBZipCode(string: string) {
    const firstChar = '[ABCDEFGHIJKLMNOPRSTUWYZ]' // Does not accept QVX
    const secondChar = '[ABCDEFGHKLMNOPQRSTUVWXY]' // Does not accept IJZ
    const thirdChar = '[ABCDEFGHJKPMNRSTUVWXY]' // Does not accept ILOZ
    const fourthChar = '[ABEHMNPRVWXY]' // Does not accept CDFGIJKOQSTUZ
    const fifthChar = '[ABDEFGHJLNPQRSTUWXYZ]' // Does not accept CIKMOV
    const regexps = [
      // AN NAA, ANN NAA, AAN NAA, AANN NAA
      new RegExp(
        `^(${firstChar}{1}${secondChar}?[0-9]{1,2})(\\s*)([0-9]{1}${fifthChar}{2})$`,
        'i'
      ),
      // ANA NAA
      new RegExp(
        `^(${firstChar}{1}[0-9]{1}${thirdChar}{1})(\\s*)([0-9]{1}${fifthChar}{2})$`,
        'i'
      ),
      // AANA NAA
      new RegExp(
        `^(${firstChar}{1}${secondChar}{1}?[0-9]{1}${fourthChar}{1})(\\s*)([0-9]{1}${fifthChar}{2})$`,
        'i'
      ),
      new RegExp(
        '^(BF1)(\\s*)([0-6][ABDEFGHJLNPQRST][ABDEFGHJLNPQRSTUWZY])$',
        'i'
      ), // BFPO postcodes
      /^(GIR)(\s*)(0AA)$/i, // Special postcode GIR 0AA
      /^(BFPO)(\s*)([0-9]{1,4})$/i, // Standard BFPO numbers
      /^(BFPO)(\s*)(c\/o\s*[0-9]{1,3})$/i, // c/o BFPO numbers
      /^([A-Z]{4})(\s*)(1ZZ)$/i, // Overseas Territories
      /^(AI-2640)$/i // Anguilla
    ]

    return !!regexps.find((regexp) => regexp.test(string))
  }

  private validateZipCode(string?: string) {
    const country = this.country
    const value = string ? string.trim().toUpperCase() : ''

    switch (country) {
      case 'AT':
      case 'BE':
        return /^([1-9])(\d{3})$/.test(value)
      case 'CA':
        return /^(?:A|B|C|E|G|H|J|K|L|M|N|P|R|S|T|V|X|Y)[0-9](?:A|B|C|E|G|H|J|K|L|M|N|P|R|S|T|V|W|X|Y|Z)\s?[0-9](?:A|B|C|E|G|H|J|K|L|M|N|P|R|S|T|V|W|X|Y|Z)[0-9]$/i.test(
          value
        )
      case 'CH':
        return /^\d{4}$/.test(value)
      case 'DE':
        return /^(?!01000|99999)(0[1-9]\d{3}|[1-9]\d{4})$/.test(value)
      case 'DK':
        return /^(DK(-|\s)?)?\d{4}$/i.test(value)
      case 'ES':
        return /^(?:0[1-9]|[1-4][0-9]|5[0-2])\d{3}$/.test(value)
      case 'FI':
        return /^(FI-)?\d{5}$/i.test(value)
      case 'FR':
        return /^[0-9]{5}$/i.test(value)
      case 'GB':
        return this.validateGBZipCode(value)
      case 'IE':
        return (
          value.length === 0 ||
          /^(D6W|[ACDEFHKNPRTVWXY]\d{2})\s?[0-9ACDEFHKNPRTVWXY]{4}$/.test(value)
        )
      case 'IT':
        return /^(I-|IT-)?\d{5}$/i.test(value)
      case 'LU':
        return /^([Ll]-)?[1-9]([0-9]{3})$/.test(value)
      case 'NL':
        return /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i.test(value)
      case 'NO':
        return /^(N-|NO-)?\d{4}$/i.test(value)
      case 'PR':
        return postcodeValidator(value, country) && /^00[679]/.test(value)
      case 'PT':
        return /^[1-9]\d{3}-\d{3}$/.test(value)
      case 'SE':
        return /^(SE-)?\d{3}\s?\d{2}$/i.test(value)
      case 'US':
        return /^\d{5}(-?\d{4})?$/.test(value)
      default:
        return postcodeValidator(value, country)
    }
  }
}
