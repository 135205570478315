import SubscriptionItem from '@/components/SubscriptionItem'
import getAuthProvider from '@/helpers/getAuthProvider'
import getLocalization from '@/helpers/getLocalization'
import useBillingInfo from '@/hooks/useBillingInfo'
import useGetText from '@/hooks/useGetText'
import useShellContext from '@/hooks/useShellContext'
import useUserInfo from '@/hooks/useUserInfo'
import { SubscriptionType } from '@/types/SubscriptionType'
import getSubscriptionTitleTextId from '@/helpers/getSubscriptionTitleTextId'
import HandleTaxInfo from '@/components/Billing/HandleTaxInfo'
import { setInlineNotification } from '@/actions/Actions'
import { instantInkStates } from '@/utils/instantInkStates'
import {
  BillingModalWindowCancelBtnClicked,
  BillingModalWindowContinueBtnClicked,
  BillingModalWindowDisplayed,
  BillingModuleDisplayed,
  EditPaymentLinkClicked,
  publishEvent
} from '@/utils/analytics'
import { BillingForm } from '@jarvis/react-billing'
import Card from '@veneer/core/dist/scripts/card'
import Modal from '@veneer/core/dist/scripts/modal'
import React, { useContext, useEffect, useState } from 'react'
import { PaymentMethodType } from '../../../types/PaymentMethodType'

import {
  BillingItemContainer,
  BillingSectionContainer,
  ModalContentContainer,
  StyledBillingDetailsContainer,
  StyledBillingTileContainer,
  StyledEditButtonContainer
} from './styles'

import CreditCard from '../CreditCard'
import { useAppContext } from '@/context/AppContext'
import useCookie from '@/hooks/useCookie/useCookies'
import PayPal from '../PayPal'
import AddPaymentMethod from '../AddPaymentMethod'
import { WarningContext } from '@/context/WarningContext'
import useTaxId from '@/hooks/useTaxId/useTaxId'

const PaymentMethod = () => {
  const isAgentSession = useCookie('is_agent_session')
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
  const { setWarningType } = useContext(WarningContext)
  const getText = useGetText('billing')
  const getTextSub = useGetText('subscriptions')
  const title = getTextSub(
    getSubscriptionTitleTextId(SubscriptionType.INSTANT_INK)
  )
  const shell = useShellContext()
  const authProvider = getAuthProvider()
  const [
    currentBillingModalStep,
    setCurrentBillingModalStep
  ] = useState<number>(1)
  const { dispatch } = useAppContext()
  const { instantInkSubscriptionInfo, account } = useAppContext().state

  const {
    info: { data: billingInfo },
    forceRefresh
  } = useBillingInfo()
  const { language } = getLocalization()
  const billingBaseURLProvider = async () =>
    `${process.env.BILLING_BASE_URL_PROVIDER}/instantink/v1/commerce`

  const { userName } = useUserInfo()

  const country = account ? account.data.regionId : process.env.DEFAULT_COUNTRY
  const currLanguage = language || process.env.DEFAULT_LANGUAGE

  const {
    config: { show },
    data: taxData,
    forceRefresh: forceTaxIdRefresh
  } = useTaxId()

  const instantInkState = [
    instantInkStates.SUBSCRIBED_NO_PENS,
    instantInkStates.SUBSCRIBED_NO_PRINTER,
    instantInkStates.SUBSCRIBED,
    instantInkStates.INITIATED_UNSUBSCRIBE,
    instantInkStates.UNSUBSCRIBED
  ]

  const editTaxId = instantInkSubscriptionInfo?.data?.instantInkSubscriptions?.find(
    ({ state }) => instantInkState.includes(state as instantInkStates)
  )

  useEffect(() => {
    publishEvent(
      { ...BillingModuleDisplayed, screenMode: 'InstantInk' },
      {
        actionParams: { subscriptionId: subscriptionIds.join('+') }
      }
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (
      billingInfo &&
      !billingInfo?.paymentType &&
      instantInkSubscriptionInfo?.data?.instantInkSubscriptions.length > 0
    ) {
      setWarningType('billingWarning')
    } else if (
      billingInfo?.paymentType &&
      instantInkSubscriptionInfo?.data?.instantInkSubscriptions.length > 0
    ) {
      setWarningType('noWarning')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billingInfo, instantInkSubscriptionInfo])

  const editBillingClick = () => {
    setIsOpenModal(!isOpenModal)
    publishEvent(
      { ...EditPaymentLinkClicked, screenMode: 'InstantInk' },
      { actionParams: { subscriptionId: subscriptionIds.join('+') } }
    )
    publishEvent({
      ...BillingModalWindowDisplayed,
      screenName: `Billing1`,
      screenMode: 'InstantInk'
    })
  }

  const handleCloseModal = () => {
    setIsOpenModal(false)
    publishEvent({
      ...BillingModalWindowCancelBtnClicked,
      screenName: `Billing${currentBillingModalStep}`,
      screenMode: 'InstantInk'
    })
    setCurrentBillingModalStep(1)
  }

  const handleUpdate = () => {
    publishEvent({
      ...BillingModalWindowContinueBtnClicked,
      screenName: `Billing1`,
      screenMode: 'InstantInk'
    })
    publishEvent({
      ...BillingModalWindowDisplayed,
      screenName: `Billing2`,
      screenMode: 'InstantInk'
    })
    setCurrentBillingModalStep(2)
  }

  const onSave = async (err: string) => {
    setIsOpenModal(false)
    const notification = err
      ? {
          display: true,
          title: getText('failedApi', {
            defaultValue: 'Something went wrong. Try again later.'
          }),
          status: 'warning' as const
        }
      : {
          display: true,
          title: getText('billingSavedSuccess.instantInkTitle', {
            defaultValue: 'Your Instant Ink payment method has been saved.'
          }),
          status: 'positive' as const
        }
    dispatch(setInlineNotification(notification))
    publishEvent({
      ...BillingModalWindowContinueBtnClicked,
      screenName: `Billing2`,
      screenMode: 'InstantInk'
    })
    forceRefresh()
    forceTaxIdRefresh()
  }

  const subscriptionIds = []
  instantInkSubscriptionInfo?.data?.instantInkSubscriptions?.forEach((item) => {
    subscriptionIds.push(item.accountIdentifier)
  })

  const paymentMethodSubscriptionContent = instantInkSubscriptionInfo &&
    subscriptionIds && (
      <SubscriptionItem
        subscriptionType={SubscriptionType.INSTANT_INK}
        subscriptionIds={subscriptionIds}
        title={title}
      />
    )

  const paymentMethodDetailsContent = (
    <StyledBillingTileContainer>
      {paymentMethodSubscriptionContent}

      <StyledBillingDetailsContainer>
        {billingInfo?.paymentType === PaymentMethodType.pay_pal ? (
          <PayPal userName={userName} billingInfo={billingInfo} />
        ) : (
          <CreditCard
            userName={userName}
            billingInfo={billingInfo}
            t={getText}
          />
        )}
        <StyledEditButtonContainer>
          <a
            role="button"
            data-testid="editbillingButton"
            onClick={editBillingClick}
          >
            {getText('editPayment')}
          </a>
        </StyledEditButtonContainer>
        {show && editTaxId && (
          <HandleTaxInfo
            taxId={taxData?.taxId}
            nonProfitTaxId={taxData?.nonProfitTaxId}
            allowEditingTaxId={taxData?.allowEditingTaxId}
            forceRefresh={forceTaxIdRefresh}
          />
        )}
      </StyledBillingDetailsContainer>
    </StyledBillingTileContainer>
  )

  const paymentMethodTileContent = (
    <BillingSectionContainer data-testid="paymentMethodTileContent">
      {billingInfo?.paymentType ? (
        paymentMethodDetailsContent
      ) : (
        <AddPaymentMethod />
      )}

      <Modal closeButton show={isOpenModal} onClose={handleCloseModal}>
        {isOpenModal ? (
          <ModalContentContainer>
            <BillingForm
              accountIdentifier={'testingId'}
              country={country}
              language={currLanguage}
              baseURLProvider={billingBaseURLProvider}
              authProvider={authProvider}
              mockStratus={false}
              onSave={(err: string) => onSave(err)}
              onCancel={handleCloseModal}
              onUpdate={handleUpdate}
              stack={shell?.stack}
              virtualKeyboard={isAgentSession}
              nativeApp={shell?.isNative}
            />
          </ModalContentContainer>
        ) : (
          <></>
        )}
      </Modal>
    </BillingSectionContainer>
  )

  return (
    <BillingItemContainer>
      <Card
        content={paymentMethodTileContent}
        className="card-box"
        customStyle={{ borderRadius: 8 }}
      />
    </BillingItemContainer>
  )
}

export default PaymentMethod
