import { useEffect } from 'react'
import { useBillingFormState } from './useBillingFormState'
import { useDispatch } from './useDispatch'
import { fetchPaymentMethodInfoAction } from '../actions'

export const usePaymentMethodInfoLoading = () =>
  useBillingFormState().paymentMethodInfoLoading

export const usePaymentMethodInfoError = () =>
  useBillingFormState().paymentMethodInfoError

export const usePaymentMethodInfo = () => {
  const {
    paymentMethodInfo,
    paymentMethodInfoLoading,
    paymentMethodInfoError
  } = useBillingFormState()
  const dispatch = useDispatch()

  useEffect(() => {
    if (
      paymentMethodInfo === undefined &&
      !paymentMethodInfoLoading &&
      !paymentMethodInfoError
    ) {
      ;(async () => {
        try {
          await dispatch(fetchPaymentMethodInfoAction())
        } catch {
          // do nothing
        }
      })()
    }
  }, [
    paymentMethodInfo,
    paymentMethodInfoLoading,
    paymentMethodInfoError,
    dispatch
  ])

  return paymentMethodInfo
}
