import styled from 'styled-components'
import tokens from '@veneer/tokens'

interface StyledTitleProps {
  isDisabled?: boolean
}

export const Container = styled.div`
  && {
    font-size: ${tokens.typography.size2};
    font-weight: 400;
    font-family: ${tokens.typography.family0};
    color: ${tokens.color.gray9};
    letter-spacing: 0.02em;
  }
`

export const SubscriptionLineItemContainer = styled.div<StyledTitleProps>`
  && {
    img {
      width: 5.2rem;
      height: 1rem;
      opacity: ${(props) => (props.isDisabled ? 0.5 : 1)};
      margin-bottom: ${tokens.layout.size2};
    }
    padding: 0 0 4px 2px;
  }
`

export const StyledTitle = styled.div<StyledTitleProps>`
  && {
    font-family: ${tokens.typography.family0};
    font-size: ${tokens.typography.size0};
    font-weight: 400;
    color: ${(props) =>
      props.isDisabled ? tokens.color.highContrastGray : '#737373'};
    line-height: ${tokens.typography.size2};
    letter-spacing: 0.04em;
    margin-bottom: ${tokens.layout.size1};
  }
`

export const StyledSubscriptionContainer = styled.div<StyledTitleProps>`
  && {
    font-family: ${tokens.typography.family0};
    font-size: ${tokens.typography.size0};
    font-weight: 400;
    color: ${(props) =>
      props.isDisabled ? tokens.color.highContrastGray : tokens.color.gray12};
    line-height: ${tokens.typography.size2};
    letter-spacing: 0.04em;
  }
`
