import Images from '../assets/images'

export default function getSubscriptionItemImage(
  type: string,
  state?: 'DEFAULT' | 'DISABLED'
): string {
  switch (type) {
    case 'instant-ink':
    case 'iot-printer':
      return Images.instant_ink_single_line
    case 'hp_one':
    case 'iot-pc':
    case 'chromebook':
      if (state === 'DISABLED') {
        return Images.hp_all_in_plan_disabled
      } else {
        return Images.hp_all_in_plan
      }
    default:
      return ''
  }
}
