import InstantInkShippingAddress from '@/types/InstantInkShippingAddress'
import { JarvisAuthProvider } from '@jarvis/web-http'
import axios, { AxiosInstance } from 'axios'
import InfoDatas from 'src/types/InfoDatas'
import { ShippingAddress } from 'src/types/ShippingAddress'
import { forceLoginHandling } from '@/utils/forceLoginHandling'
import sendOpenTelemetryEvent from '@/helpers/sendOpenTelemetryEvent'
export default class ShippingClient {
  httpClient: AxiosInstance
  xCorrelationId: string
  baseURL: string
  billingEndpoint: string
  mfeAuthProvider: JarvisAuthProvider
  intantInkBaseURL: string

  constructor(authProvider: JarvisAuthProvider, xCorrelationId: string) {
    this.httpClient = axios
    this.httpClient.defaults.params = {}
    this.mfeAuthProvider = authProvider
    this.xCorrelationId = xCorrelationId
    this.baseURL = process.env.SHIPPING_BASE_URL_PROVIDER
    this.intantInkBaseURL = `${process.env.BILLING_BASE_URL_PROVIDER}/instantink/v1/commerce`
  }

  async getShippingData(): Promise<InfoDatas['shippingInfo']> {
    const stratusToken = await this.mfeAuthProvider.getAccessToken()
    const headers = {
      accept: 'application/json',
      authorization: `Bearer ${stratusToken}`
    }
    return this.httpClient
      .get(this.baseURL, {
        headers
      })
      .then(({ data }) =>
        data && data.resourceList ? this.formatShippingData(data) : data
      )
      .catch((err) => {
        if (err?.response?.status === 403) {
          forceLoginHandling()
        }
        sendOpenTelemetryEvent(
          'Error while fetching data from shipping client: ' +
            JSON.stringify(err)
        )
        throw err
      })
  }

  async getIIShippingAddress(
    headers,
    cloudIdentifier
  ): Promise<ShippingAddress> {
    return await this.httpClient
      .get(
        this.intantInkBaseURL +
          '/shipping/addresses/devices/' +
          cloudIdentifier,
        {
          headers
        }
      )
      .then(({ data }) => this.formatAddressData(data, cloudIdentifier))
      .catch((err) => {
        if (err?.response?.status === 403) {
          forceLoginHandling()
        }
        sendOpenTelemetryEvent(
          'Error while fetching shipping address from shipping II client: ' +
            JSON.stringify(err)
        )
        throw err
      })
  }

  async getInstantInkShippingAddresses(
    cloudIdentifiers: string[]
  ): Promise<InfoDatas['instantInkDeviceAddressInfo']> {
    const stratusToken = await this.mfeAuthProvider.getAccessToken()
    const headers = {
      accept: 'application/json',
      authorization: `Bearer ${stratusToken}`
    }

    const instantInkDeviceAddresses = await Promise.all(
      cloudIdentifiers?.map((cloudIdentifier) =>
        this.getIIShippingAddress(headers, cloudIdentifier)
      )
    )

    return instantInkDeviceAddresses
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private formatShippingData(data: any) {
    const items = data?.resourceList?.map((item) => ({
      ...item,
      fullName: item.firstName + ' ' + item.lastName
    }))
    return items
  }

  private formatAddressData(
    data: InstantInkShippingAddress,
    cloudIdentifier: string
  ) {
    const shippingAddress: ShippingAddress = {
      ...data,
      resourceId: data.id,
      tenantId: cloudIdentifier,
      address: data.street1,
      address2: data.street2,
      postalCode: data.zipCode,
      phoneNumber: data.phoneNumber1,
      bisPrimary: data.isDefaultAddress,
      receivetextMessage: data.optedIn,
      fullName: `${data.firstName} ${data.lastName}`
    }
    return shippingAddress
  }
}
