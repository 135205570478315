import React from 'react'
import getHpOnePaymentCardInfo from '../../../helpers/getHpOnePaymentCardInfo'
import {
  StyledCCImageContainer,
  StyledDetailsInfo,
  PaymentIconInfo
} from './styles'
import { IconInfo } from '@veneer/core/dist/scripts/icons'
import { EDelinquencyNotification } from '@/types/pendingChanges'

const HpOneCreditCard = ({ billingInfo, t, expirationStatus }) => {
  const creditCardDetails = billingInfo

  const creditCardTypeInfo = getHpOnePaymentCardInfo(
    creditCardDetails?.card_type
  )
  const expireInfo =
    expirationStatus === EDelinquencyNotification.ABOUT_TO_EXPIRE ? (
      <PaymentIconInfo expirationStatus={expirationStatus}>
        <IconInfo className="iconInfo" size={15} /> Expiring soon:
        {creditCardDetails?.expiration_date}
      </PaymentIconInfo>
    ) : expirationStatus === EDelinquencyNotification.EXPIRED ? (
      <PaymentIconInfo expirationStatus={expirationStatus}>
        <IconInfo className="iconInfo" size={15} /> Expired
      </PaymentIconInfo>
    ) : (
      <>
        {t('expires')} {creditCardDetails?.expiration_date}
      </>
    )

  return (
    <>
      <StyledCCImageContainer>
        <img
          src={creditCardTypeInfo?.cardImage}
          alt={creditCardTypeInfo?.cardName}
        />
      </StyledCCImageContainer>

      <StyledDetailsInfo>
        {creditCardDetails?.name_on_card && creditCardDetails.name_on_card}
      </StyledDetailsInfo>

      <StyledDetailsInfo>
        {creditCardDetails?.last_four &&
          `XXXX-XXXX-XXXX-${creditCardDetails.last_four}`}
      </StyledDetailsInfo>
      <StyledDetailsInfo>
        <>{expireInfo}</>
      </StyledDetailsInfo>
    </>
  )
}

export default HpOneCreditCard
