import { useEffect, useState } from 'react'
import { EDelinquencyNotification } from '@/types/pendingChanges'
import { getExpirationStatus } from '@/helpers/getExpirationStatus'
import useSubscriptionInfo from '../useSubscriptionInfo'
import { SubscriptionInfo } from '@/types/SubscriptionInfo'
import { PendingChangesClient } from '@/services/PendingChanges/PendingChangesClient'
import useShellContext from '../useShellContext'
import getAuthProvider from '@/helpers/getAuthProvider'
import { Stack } from '@jarvis/web-stratus-client'
import { JarvisAuthProvider } from '@jarvis/web-http'
import sendOpenTelemetryEvent from '@/helpers/sendOpenTelemetryEvent'

const useDelinquencyNotification = (expirationDate: string) => {
  const notification_status = useDelinquencyWithHistoryNotification()

  if (
    notification_status?.notificationStatus === EDelinquencyNotification.NONE
  ) {
    const expirationStatus = getExpirationStatus(expirationDate)
    return expirationStatus?.notificationStatus
  } else {
    return notification_status?.notificationStatus
  }
}

const fetchPendingChanges = async (
  printer: SubscriptionInfo,
  authProvider: JarvisAuthProvider,
  stack: Stack
) => {
  const pendingChangesClient = new PendingChangesClient(authProvider, stack)
  try {
    const { data } = await pendingChangesClient.getPendingChanges(
      printer?.subscriptionId
    )
    const hasPaymentIssueSuspended = data.contents.some(
      (item) => item.type === 'paymentIssue' && item.value.state === 'suspended'
    )

    return hasPaymentIssueSuspended
      ? EDelinquencyNotification.OVERDUE
      : EDelinquencyNotification.NONE
  } catch (e) {
    sendOpenTelemetryEvent(
      'Error while fetching get pending changes: ' + JSON.stringify(e)
    )
    return EDelinquencyNotification.NONE
  }
}

export const useDelinquencyWithHistoryNotification = () => {
  const {
    info: { data: hpOneSubscriptionInfo, isFetching: hpOneIsFetching }
  } = useSubscriptionInfo()

  const { stack } = useShellContext()
  const authProvider = getAuthProvider()

  const [subscription, setSubscription] = useState<SubscriptionInfo[]>()
  const [
    notificationStatus,
    setNotificationStatus
  ] = useState<EDelinquencyNotification>()

  useEffect(() => {
    setSubscription(hpOneSubscriptionInfo)
  }, [hpOneSubscriptionInfo, hpOneIsFetching])

  useEffect(() => {
    const fetchData = async () => {
      if (subscription && subscription[0]?.state === 'active') {
        const notificationStatus = await fetchPendingChanges(
          subscription[0],
          authProvider,
          stack
        )
        setNotificationStatus(notificationStatus)
      }
      //  else if (subscription && printer?.state === 'inactive') {
      //   setNotificationStatus(EDelinquencyNotification.INACTIVE)
      // }
      else if (subscription && subscription[0]?.state === 'suspended') {
        setNotificationStatus(EDelinquencyNotification.SUSPENDED)
      } else {
        setNotificationStatus(EDelinquencyNotification.NONE)
      }
    }

    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscription])

  return { notificationStatus }
}

export default useDelinquencyNotification
