import { EDelinquencyNotification } from '@/types/pendingChanges'

const getBorderStyle = (expirationStatus: EDelinquencyNotification): string => {
  if (!expirationStatus) return ''
  const colorInfo =
    expirationStatus === EDelinquencyNotification.ABOUT_TO_EXPIRE
      ? '1px solid #FFA500'
      : expirationStatus === EDelinquencyNotification.EXPIRED
      ? '1px solid #FF0000'
      : expirationStatus === EDelinquencyNotification.OVERDUE
      ? '1px solid #FF8900'
      : '' // a placeholder to handle box Boarder color

  return colorInfo
}
export default getBorderStyle
