import { useEffect, useState } from 'react'
import UserMgtSvcClient from '../../services/UserMgtSvcClient'
import getAuthProvider from '@/helpers/getAuthProvider'
import useShellContext from '@/hooks/useShellContext'

export default function useUserMgtSvcClient() {
  const [userMgtSvcClient, setUserMgtSvcClient] = useState(null)
  const authProvider = getAuthProvider()
  const shell = useShellContext()

  useEffect(() => {
    setUserMgtSvcClient(new UserMgtSvcClient(shell?.stack, authProvider))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return userMgtSvcClient
}
