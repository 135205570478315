import { useEffect, useState } from 'react'
import useSubscriptionInfo from '../useSubscriptionInfo'
import { SubscriptionInfo } from '@/types/SubscriptionInfo'
import usePaymentMethodsInfo from '../usePaymentMethodsInfo'
import { PaymentMethodDetails } from '@/types/PaymentMethodsInfo'

const usePaymentDetails = () => {
  interface ErrorResponse {
    response?: {
      status?: number
    }
  }
  const {
    info: { data: hpOneSubscriptionInfo, isFetching: hpOneIsFetching },
    forceRefresh: subscriptionInfoRefresh
  } = useSubscriptionInfo()
  const [isBillingError, setIsBillingError] = useState<boolean>(false)
  const paymentMethodId = hpOneSubscriptionInfo?.[0]?.paymentMethodId
  const {
    info: { data: billingInfo, error: billingInfoError },
    forceRefresh: billingInfoRefresh
  } = usePaymentMethodsInfo(paymentMethodId)

  useEffect(() => {
    if (paymentMethodId) {
      billingInfoRefresh()
    }
  }, [paymentMethodId])

  const status = (billingInfoError as ErrorResponse)?.response?.status
  if (status) {
    if (!isBillingError) setIsBillingError(true)
  }

  const [subscription, setSubscription] = useState<SubscriptionInfo[]>()
  const [paymentMethodDetails, setPaymentMethodDetails] = useState<
    Partial<PaymentMethodDetails>
  >()

  useEffect(() => {
    setSubscription(hpOneSubscriptionInfo)
  }, [hpOneSubscriptionInfo, hpOneIsFetching])

  useEffect(() => {
    const cardDetails = billingInfo?.payment_method_details

    setPaymentMethodDetails(cardDetails)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscription, billingInfo])

  const forceRefresh = () => {
    subscriptionInfoRefresh()
    billingInfoRefresh()
  }
  return { paymentMethodDetails, isBillingError, forceRefresh }
}

export default usePaymentDetails
