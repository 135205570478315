import { ShippingAddress } from '@/types/ShippingAddress'
import { SubscriptionInfo } from '@/types/SubscriptionInfo'
import { SubscriptionEntity } from '@/types/SubscriptionEntity'
import { getDevices } from './getDevices'
import { getReplacedEntity } from './getReplacedEntity'

export default function getSubscriptionShippingAddressInfo(
  shippingInfos: ShippingAddress[],
  subscriptionInfos: SubscriptionInfo[]
): SubscriptionInfo[] {
  const mappedSubscriptions = subscriptionInfos?.map((subItem) => {
    const entityItem = getDisplaySubscriptionEntity(subItem)

    const shippingAddress = shippingInfos?.find(
      (s) => s.resourceId === entityItem.shippingAddressId
    )
    entityItem.shippingAddress = shippingAddress
    const mergedEntity = {
      ...subItem,
      subscriptionEntity: entityItem
    }
    return mergedEntity
  })
  return mappedSubscriptions
}

export function getReplacementEntityId(
  entity: SubscriptionEntity
): string | undefined {
  const replacedWithEntity = entity.linkedEntities.find(
    (linkedEntity) =>
      linkedEntity.type === 'replacedBy' &&
      !entity.linkedEntities.some(
        (innerLinkedEntity) => innerLinkedEntity.type === 'replacedWith'
      )
  )
  return replacedWithEntity?.value
}

export function getDisplaySubscriptionEntity(
  subscriptionInfo: SubscriptionInfo
): SubscriptionEntity {
  const devices = getDevices(subscriptionInfo.entities)

  if (devices && devices.length === 1) return devices[0]

  return devices && getReplacedEntity(devices)
}
