import { useState, useEffect } from 'react'

export function getCookies(name: string) {
  const cookies = document.cookie.split(';')
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim()
    if (cookie.startsWith(name + '=')) {
      return cookie.substring(name.length + 1)
    }
  }
  return null
}

function useCookies(key: string): boolean {
  const [isCookieUsed, setIsCookieUsed] = useState(false)

  useEffect(() => {
    const checkCookie = getCookies(key)
    if (checkCookie !== null && checkCookie === 'true') {
      setIsCookieUsed(true)
    } else {
      setIsCookieUsed(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return isCookieUsed
}

export default useCookies
