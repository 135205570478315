import { StratusClient } from './stratusClient'
import { AxiosResponse } from 'axios'
import { BillingAddress, NewBillingAddress } from '../types'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IAddressService {
  createAddress(
    address: NewBillingAddress
  ): Promise<AxiosResponse<BillingAddress>>

  updateAddress(address: BillingAddress): Promise<AxiosResponse<BillingAddress>>
}

export class AddressService extends StratusClient implements IAddressService {
  apiName = 'ws-hp.com/addresssvc'
  apiVersion = '1'
  addressesEndpoint = '/addresses'

  createAddress(
    address: NewBillingAddress
  ): Promise<AxiosResponse<BillingAddress>> {
    return this.jarvisWebHttpInstance.post({
      url: this.addressesEndpoint,
      headers: this.defaultHeaders(),
      data: { ...address }
    })
  }

  updateAddress(
    address: BillingAddress
  ): Promise<AxiosResponse<BillingAddress>> {
    return this.jarvisWebHttpInstance.patch({
      url: `${this.addressesEndpoint}/${address.resourceId}`,
      headers: this.defaultHeaders(),
      data: { ...address }
    })
  }
}
