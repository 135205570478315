import styled, { css } from 'styled-components'

export const StyledBillingTitle = styled.h2`
  margin: 0;
  font-family: 'Forma DJR Micro', Arial Bold;
  font-weight: normal;
  font-size: 10px;

  ${(props) => {
    const mobile = css`
      font-size: 24px;
      line-height: 32px;
    `
    const tabPort = css`
      font-size: 32px;
      line-height: 40px;
    `
    const tabLandDesk = css`
      font-size: 40px;
      line-height: 48px;
    `
    const responsive = {
      'mobile-container': mobile,
      'tablet-portrait-container': tabPort,
      'tablet-landscape-container': tabLandDesk,
      'desktop-container': tabLandDesk
    }
    return responsive[props.theme.containerSize]
  }}
`
