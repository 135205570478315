import styled from 'styled-components'
import tokens from '@veneer/tokens'

export const Container = styled.div`
  background-color: #f8fafb;
  padding: ${tokens.typography.size9};
`

export const StyledTitle = styled.div`
  && {
    font-family: ${tokens.typography.family0};
    color: #737373;
    font-size: ${tokens.typography.size5};
    line-height: 32px;
    font-weight: 400;
    padding: 12px 24px 0 0;
    margin: 20px 0 0 0;
  }
`
export const StyledMainTitle = styled.div`
  && {
    font-family: ${tokens.typography.family0};
    color: ${tokens.color.gray12};
    font-size: ${tokens.typography.size7};
    line-height: 40px;
    font-weight: 400;
  }
`
export const Dividend = styled.div`
  && {
    padding: 0 0 12px 0;
  }
`
export const DelinquencyNotificationStyle = styled.div`
  padding: 0;
  margin-top: 1rem;
  margin-bottom: 1px;
`
export const InlineNotificationWrapper = styled.div`
  padding: 0;
  width: 100%;
  font-family: ${tokens.typography.family0};
  font-size: ${tokens.typography.size1};
  line-height: ${tokens.typography.lineHeight2};
  font-weight: 700;

  > div {
    border-radius: 0;
    margin: 0;
    padding: 0 40px;
  }
`
