import { PickupPHCTokenResponse } from '../../types'

export const pickupPHCTokenResponseFixture = ({
  id = '6f6a5519-bcec-4141-ac00-a7ceebdc96f2',
  tenant_id = '0a0e99ae-bebf-76dc-8ac0-114e9bbd98a1',
  billing_address_id = '62b4a382bc6cf1013885e2e2',
  payment_provider_id = 'PGS'
} = {}): PickupPHCTokenResponse => ({
  id,
  tenant_id,
  billing_address_id,
  payment_provider_id
})
