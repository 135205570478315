import React, { useEffect } from 'react'
import { ShippingForm as ShippingHPOneForm } from '@monetization/shipping-react'
import useShellContext from '@/hooks/useShellContext'
import getLocalization from '@/helpers/getLocalization'
import getAuthProvider from '@/helpers/getAuthProvider'
import useGetText from '@/hooks/useGetText'
import Images from '../../../assets/images'
import Card from '@veneer/core/dist/scripts/card'
import SubscriptionItem from '@/components/SubscriptionItem'
import { ShippingAddress } from '@/types/ShippingAddress'
import { ShippingForm as ShippingInstantInkForm } from '@jarvis/react-shipping'
import { setInlineNotification } from '@/actions/Actions'
import { useAppContext } from '@/context/AppContext'

import {
  ModalContentContainer,
  StyledAddressItemContainer,
  StyledButtonContainer,
  StyledContent,
  StyledDisabled,
  StyledIconContainer,
  StyledModal,
  StyledTitle
} from './styles'

import getSubscriptionLineItemEntityType from '@/helpers/getSubscriptionLineItemEntityType'
import { SubscriptionType } from '@/types/SubscriptionType'
import {
  EditShippingCancelButtonClicked,
  EditShippingClicked,
  EditShippingModuleDisplayed,
  EditShippingSaveButtonClicked,
  publishEvent,
  ShippingAddressesModuleDisplayed
} from '@/utils/analytics'
import { IconChevronRight } from '@veneer/core/dist/scripts/icons'
import useWarningType from '@/hooks/useWarningType'
import getSubscriptionTitleTextId from '@/helpers/getSubscriptionTitleTextId'

export type AdressInfoProps = React.PropsWithChildren<{
  shippingAddress: ShippingAddress
  friendlysubscriptionId: string
  subscriptionId: string
  entityType: string
  enableEdit?: boolean
  onAddressSaved: () => void
  isDisabled?: boolean
  cloudId?: string
}>

export const instankInkURLProvider = async () =>
  `${process.env.INSTANTINK_BASE_URL_PROVIDER}/instantink/v1/commerce`

export default function AddressInfo({
  shippingAddress,
  friendlysubscriptionId,
  subscriptionId,
  entityType,
  enableEdit = true,
  onAddressSaved,
  isDisabled,
  cloudId
}: AdressInfoProps) {
  const [isOpen, setOpenModal] = React.useState(false)
  const getText = useGetText('shipping')
  const { dispatch } = useAppContext()
  const shell = useShellContext()
  const { language } = getLocalization()
  const authProvider = getAuthProvider()
  const getTextSub = useGetText('subscriptions')
  const subscriptionContent = entityType && friendlysubscriptionId && (
    <SubscriptionItem
      subscriptionType={entityType}
      subscriptionIds={[friendlysubscriptionId]}
      isDisabled={isDisabled}
      title={getTextSub(getSubscriptionTitleTextId(entityType))}
    />
  )
  const translatedEntityType = getSubscriptionLineItemEntityType(entityType)
  const country = process.env.DEFAULT_COUNTRY
  const shippingAddressInfo = shippingAddress
  const hponeBaseURLProvider = process.env.SHIPPING_BASE_URL_PROVIDER

  const updateURLProvider = process.env.SHIPPING_BASE_UPDATE_URL_PROVIDER

  useEffect(() => {
    publishEvent(ShippingAddressesModuleDisplayed, {
      actionParams: { subscriptionId: subscriptionId },
      entityType: translatedEntityType
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleShippingBtn = () => {
    publishEvent(EditShippingClicked, {
      actionParams: { subscriptionId: subscriptionId },
      entityType: translatedEntityType
    })
    setOpenModal(!isOpen)
  }

  const onSave = async () => {
    publishEvent(EditShippingSaveButtonClicked, {
      entityType: translatedEntityType
    })
    dispatch(
      setInlineNotification({
        display: true,
        title: getText('shippingAddressSavedSuccess.title'),
        status: 'positive'
      })
    )
    setOpenModal(false)
    onAddressSaved()
  }

  const handleCloseModal = () => {
    publishEvent(EditShippingCancelButtonClicked, {
      entityType: translatedEntityType
    })
    setOpenModal(false)
  }

  const onFormOpen = () => {
    publishEvent(EditShippingModuleDisplayed, {
      entityType: translatedEntityType
    })
  }

  const modalContent = (addressId?) => (
    <StyledModal
      data-testid="modal"
      closeButton
      show={isOpen}
      onClose={handleCloseModal}
    >
      {isOpen ? (
        <ModalContentContainer>
          {entityType === SubscriptionType.INSTANT_INK ? (
            <ShippingInstantInkForm
              data-testid="editInstantInkShippingFormId"
              country={country}
              language={language}
              baseURLProvider={instankInkURLProvider}
              authProvider={authProvider}
              onSave={onSave}
              onCancel={handleCloseModal}
              stack={shell?.stack}
              cloudId={cloudId}
              saveButtonText={getText('save')}
              hideTitle={false}
              secondaryButton={null}
            />
          ) : (
            <ShippingHPOneForm
              data-testid="editShippingFormId"
              country={country}
              language={language}
              subscriptionType={entityType}
              baseURLProvider={hponeBaseURLProvider}
              updateURLProvider={updateURLProvider}
              authProvider={authProvider}
              onSave={onSave}
              onCancel={handleCloseModal}
              stack={shell?.stack}
              addressId={addressId}
              onOpen={onFormOpen}
            />
          )}
        </ModalContentContainer>
      ) : (
        <></>
      )}
    </StyledModal>
  )

  const containResourceId = Boolean(shippingAddressInfo?.resourceId)
  useWarningType(containResourceId ? 'noWarning' : 'shippingWarning')

  const addressButtonActionLabel = containResourceId ? (
    getText('editShippingAddress')
  ) : (
    <>
      {getText('addShippingAddress')}
      <IconChevronRight
        size={20}
        customStyle={{
          marginLeft: '5px',
          color: '#0278ab',
          verticalAlign: 'middle'
        }}
      />
    </>
  )

  const actionBtn =
    enableEdit && !isDisabled ? (
      <a
        role="button"
        data-testid={`editshippingButton_${shippingAddressInfo?.resourceId}`}
        onClick={handleShippingBtn}
        tabIndex={0}
        id={`edit-shipping-address-${entityType}`}
      >
        {addressButtonActionLabel}
      </a>
    ) : (
      <StyledDisabled isDisabled={isDisabled}>
        {getText('editShippingAddress')}
      </StyledDisabled>
    )

  const getAddressIcon = (): JSX.Element => {
    return isDisabled ? (
      <img src={Images.shipping_gray} alt="title icon" />
    ) : (
      <img src={Images.shipping} alt="title icon" />
    )
  }

  const getEmptyAddressContent = (): JSX.Element => {
    return (
      <StyledContent data-testid="emptyAddressContent" isDisabled={isDisabled}>
        <img src={Images.shipping} alt="title icon" />
        <p>{getText('noShipping')}</p>
      </StyledContent>
    )
  }

  const cardContent = (
    <>
      <StyledAddressItemContainer data-testid="addressItem">
        {subscriptionContent}
        <StyledIconContainer>
          {containResourceId && getAddressIcon()}
        </StyledIconContainer>
        <StyledTitle isDisabled={isDisabled}>
          {containResourceId && shippingAddressInfo?.fullName}
        </StyledTitle>
        <StyledContent isDisabled={isDisabled}>
          {containResourceId && shippingAddressInfo?.address} <br />
          {containResourceId && shippingAddressInfo?.address2 ? (
            <span>{shippingAddressInfo?.address2}</span>
          ) : (
            <></>
          )}
        </StyledContent>
        <StyledContent isDisabled={isDisabled}>
          {containResourceId && (
            <span>
              {shippingAddressInfo?.city}, {shippingAddressInfo?.state}{' '}
              {shippingAddressInfo?.postalCode}
            </span>
          )}
        </StyledContent>
        <StyledButtonContainer>
          {!containResourceId && getEmptyAddressContent()}
          {actionBtn}
        </StyledButtonContainer>
      </StyledAddressItemContainer>
      {modalContent(shippingAddressInfo?.resourceId)}
    </>
  )

  return (
    <Card
      content={cardContent}
      className="card-box"
      customStyle={{ borderRadius: 8 }}
    />
  )
}
