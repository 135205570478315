import { AnalyticsEventType } from '@/types/AnalyticsEventType'

const activity = 'AcctMgmt-v01'
const screenPath = {
  MFE: '/ShippingBillingManagementReact/',
  ShippingAndBilling: '/ShippingBillingManagementReact/ShippingAndBilling/'
}
const eventDetailVersion = '1.3.0'

type extraProps = {
  controlDetail?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  actionParams?: Record<string, any>
  entityType?: string
}
export const publishEvent = (
  event: Partial<AnalyticsEventType>,
  extraInfo: extraProps = null
) => {
  const publishCdmEvents = window?.Shell?.v1?.analytics?.publishCdmEvents

  if (!publishCdmEvents) {
    console.error('Shell.v1.analytics.publishCdmEvents undefined')
    return
  }

  if (extraInfo?.actionParams) {
    event.actionAuxParams = buildAnalyticsQueryString(extraInfo?.actionParams)
  }

  if (extraInfo?.controlDetail) {
    event.controlDetail = extraInfo.controlDetail
  }

  if (extraInfo?.entityType) {
    event.screenMode = extraInfo.entityType
  }

  publishCdmEvents([
    {
      dateTime: new Date().toISOString(),
      eventDetailType:
        'com.hp.cdm.domain.telemetry.type.eventDetail.category.simpleUi.version.1',
      eventCategory: 'simpleUi',
      eventDetail: event,
      version: '1.4.0'
    }
  ])
}

export const buildAnalyticsQueryString = (props) => {
  return decodeURIComponent(new URLSearchParams(props).toString())
}

// Events
export const ShippingAndBillingScreenDisplayed: Partial<AnalyticsEventType> = {
  activity,
  screenPath: screenPath.MFE,
  screenName: 'ShippingAndBilling',
  action: 'ScreenDisplayed',
  version: eventDetailVersion
}

export const ShippingAddressesModuleDisplayed: Partial<AnalyticsEventType> = {
  activity,
  action: 'ModuleDisplayed',
  screenPath: screenPath.ShippingAndBilling,
  controlName: 'Address',
  screenName: 'ShippingAddresses',
  version: eventDetailVersion
}

export const EditShippingClicked: Partial<AnalyticsEventType> = {
  activity,
  action: 'ControlHyperLinkClicked',
  screenPath: screenPath.ShippingAndBilling,
  controlName: 'EditAddress',
  screenName: 'ShippingAddresses',
  version: eventDetailVersion
}

export const EditShippingModuleDisplayed: Partial<AnalyticsEventType> = {
  activity,
  action: 'ModalWindowDisplayed',
  screenPath: screenPath.ShippingAndBilling,
  screenName: 'EditShipping',
  version: eventDetailVersion
}

export const EditShippingCancelButtonClicked: Partial<AnalyticsEventType> = {
  activity,
  action: 'ControlButtonClicked',
  screenPath: screenPath.ShippingAndBilling,
  screenName: 'EditShipping',
  controlName: 'Cancel',
  version: eventDetailVersion
}

export const EditShippingSaveButtonClicked: Partial<AnalyticsEventType> = {
  activity,
  action: 'ControlButtonClicked',
  screenPath: screenPath.ShippingAndBilling,
  screenName: 'EditShipping',
  controlName: 'Save',
  version: eventDetailVersion
}

// BillingInformation
export const BillingModuleDisplayed: Partial<AnalyticsEventType> = {
  activity,
  screenPath: screenPath.ShippingAndBilling,
  screenName: 'BillingInformation',
  action: 'ModuleDisplayed',
  controlName: 'BillingInfo',
  version: eventDetailVersion
}

export const EditPaymentLinkClicked: Partial<AnalyticsEventType> = {
  activity,
  screenPath: screenPath.ShippingAndBilling,
  screenName: 'BillingInformation',
  action: 'ControlHyperLinkClicked',
  controlName: 'EditBillingInfo',
  version: eventDetailVersion
}

// Billing1, Billing2
export const BillingModalWindowDisplayed: Partial<AnalyticsEventType> = {
  activity,
  screenPath: screenPath.ShippingAndBilling,
  screenName: 'Billing1',
  action: 'ModalWindowDisplayed',
  version: eventDetailVersion
}

export const BillingModalWindowCancelBtnClicked: Partial<AnalyticsEventType> = {
  activity,
  screenPath: screenPath.ShippingAndBilling,
  screenName: 'Billing1',
  action: 'ControlButtonClicked',
  controlName: 'Cancel',
  version: eventDetailVersion
}

export const BillingModalWindowContinueBtnClicked: Partial<AnalyticsEventType> = {
  activity,
  screenPath: screenPath.ShippingAndBilling,
  screenName: 'Billing1',
  action: 'ControlButtonClicked',
  controlName: 'Continue',
  version: eventDetailVersion
}

export enum AnalyticScreenMode {
  InstantInk = 'InstantInk',
  HpOne = 'HpOne'
}
