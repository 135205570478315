import React, { useEffect, useMemo } from 'react'
import {
  Container,
  StyledMainTitle,
  DelinquencyNotificationStyle,
  InlineNotificationWrapper
} from './styles'
import Billing from '../Billing'
import Shipping from '../Shipping'
import useGetText from '@/hooks/useGetText'
import {
  publishEvent,
  ShippingAndBillingScreenDisplayed
} from '@/utils/analytics'

import useInstantInkSubscriptionInfo from '@/hooks/useInstantInkSubscriptionInfo'
import useSubscriptionInfo from '@/hooks/useSubscriptionInfo'
import getLocalization from '@/helpers/getLocalization'
import AssetsProviderFactory from '@/assets/AssetsProviderFactory'
import useAccount from '@/hooks/useAccount'
import {
  setAssetsProvider,
  setBillingForm,
  setInlineNotification
} from '@/actions/Actions'

import ErrorCard from '@/components/ErrorCard'
import { useAppContext } from '@/context/AppContext'
import EmptyState from '@/components/EmptyState'
import Skeleton from '../Skeleton'
import { WarningContext } from '@/context/WarningContext'
import { WarningType } from '@/types/Warning'
import HeaderNotification from '@/components/HeaderNotification'
import { DelinquencyNotification } from '@jarvis/react-hpx-shared-components'

import useDelinquencyNotification from '@/hooks/useDelinquencyNotification'
import { EDelinquencyNotification } from '@/types/pendingChanges'
import usePaymentDetails from '@/hooks/usePaymentDetails/usePaymentDetails'
import { getMainEntity } from '@/helpers/getMainEntity'
import { defaultInlineNotification } from '@/context/appReducer'
import InlineNotification from '@veneer/core/dist/scripts/inline_notification'

interface ErrorResponse {
  response?: {
    status?: number
  }
}
interface UnknownErrorResponse {
  message?: string
}

const BillingAndShipping = () => {
  const getText = useGetText()
  const { state, dispatch } = useAppContext()
  const { inlineNotification } = state
  const [warningType, setWarningType] = React.useState(
    'noWarning' as WarningType
  )
  const { language } = getLocalization()

  const {
    info: { data: Account, isFetching: accountDataIsFetching }
  } = useAccount()

  const {
    info: {
      data: iInkSubscriptionInfo,
      isFetching: iInkIsFetching,
      error: iInkError
    }
  } = useInstantInkSubscriptionInfo()

  const { paymentMethodDetails, isBillingError } = usePaymentDetails()

  const expirationDate = paymentMethodDetails?.expiration_date

  const {
    info: {
      data: hpOneSubscriptionInfo,
      isFetching: hpOneIsFetching,
      error: hpOneError
    }
  } = useSubscriptionInfo()

  const mainEntity =
    hpOneSubscriptionInfo?.length > 0 &&
    getMainEntity(hpOneSubscriptionInfo[0].entities)

  const apiError = ((hpOneError as ErrorResponse)?.response?.status == 400 ||
    (hpOneError as ErrorResponse)?.response?.status == 401 ||
    (hpOneError as ErrorResponse)?.response?.status >= 500 ||
    (hpOneError as UnknownErrorResponse)?.message === 'Network Error' ||
    (hpOneError as UnknownErrorResponse)?.message === 'Failed to fetch' ||
    (iInkError as ErrorResponse)?.response?.status == 400 ||
    (iInkError as ErrorResponse)?.response?.status == 401 ||
    (iInkError as ErrorResponse)?.response?.status >= 500 ||
    (iInkError as UnknownErrorResponse)?.message === 'Network Error' ||
    isBillingError ||
    (iInkError as UnknownErrorResponse)?.message === 'Failed to fetch') && (
    <ErrorCard getText={getText} />
  )
  useEffect(() => {
    publishEvent(ShippingAndBillingScreenDisplayed)
  }, [])

  const country = Account ? Account.regionId : process.env.DEFAULT_COUNTRY
  const currLanguage = language ? language : process.env.DEFAULT_LANGUAGE

  useEffect(() => {
    if (currLanguage && country) {
      const assetProvider = AssetsProviderFactory.create(currLanguage, country)
      dispatch(setAssetsProvider(assetProvider))
    }
    publishEvent(ShippingAndBillingScreenDisplayed)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const dataIsFetching =
    iInkIsFetching || accountDataIsFetching || hpOneIsFetching
  const hpOneSubscriptionInfolength = hpOneSubscriptionInfo?.length > 0

  const emptyStateContent = !apiError &&
    !iInkIsFetching &&
    !hpOneIsFetching &&
    ((iInkError && hpOneError) ||
      (!(iInkSubscriptionInfo?.instantInkSubscriptions?.length > 0) &&
        !hpOneSubscriptionInfolength)) && <EmptyState />

  const warningContextProviderValue = useMemo(
    () => ({ warningType, setWarningType }),
    [warningType, setWarningType]
  )

  const delinquencyStatus = useDelinquencyNotification(expirationDate)

  const handleBillingForm = () => {
    dispatch(setBillingForm(true))
  }
  return (
    <WarningContext.Provider value={warningContextProviderValue}>
      {mainEntity &&
        delinquencyStatus &&
        delinquencyStatus !== EDelinquencyNotification.NONE && (
          <DelinquencyNotificationStyle>
            <DelinquencyNotification
              notificationStatus={delinquencyStatus}
              pMRedirect={false}
              pMHandler={handleBillingForm}
            />
          </DelinquencyNotificationStyle>
        )}
      <HeaderNotification />

      {state.inlineNotification.display && (
        <InlineNotificationWrapper>
          <InlineNotification
            id={'inlineNotification'}
            onClose={() =>
              dispatch(setInlineNotification(defaultInlineNotification))
            }
            title={inlineNotification.title}
            description={inlineNotification.description}
            closeButton={true}
            type={inlineNotification.status}
          />
        </InlineNotificationWrapper>
      )}

      <Container>
        <StyledMainTitle role="heading" aria-level="1">
          {getText('mainTitle')}
        </StyledMainTitle>
        {apiError}
        {dataIsFetching && <Skeleton width="60vw" height="5vh" />}
        {emptyStateContent}
        {!apiError &&
          !emptyStateContent &&
          !iInkIsFetching &&
          !hpOneIsFetching && (
            <>
              <Shipping />
              <Billing expirationStatus={delinquencyStatus} />
            </>
          )}
      </Container>
    </WarningContext.Provider>
  )
}

export default BillingAndShipping
