import { JarvisWebHttpClient } from '@jarvis/web-http/dist/jarvis-web-http-client'
import { JarvisAuthProvider } from '@jarvis/web-http'
import {
  ApiDiscoverySvcCache,
  BaseURLProvider,
  Stack
} from '@jarvis/web-stratus-client'

export abstract class StratusClient {
  jarvisWebHttpInstance: JarvisWebHttpClient
  xCorrelationId?: string
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  apiName: string
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  apiVersion: string

  /**
   * Default constructor.
   *
   * @param baseURL the base URL stack, string or async function that returns string of the desired Stratus stack, which should be obtained from the API discovery service
   * @param authProvider the auth provider for requests this client will make
   * @param xCorrelationId the xCorrelationId to include request header
   */
  constructor(
    baseURL: BaseURLProvider | string | Stack,
    authProvider: JarvisAuthProvider,
    xCorrelationId?: string
  ) {
    let defaultBaseURL: () => Promise<string>

    if (typeof baseURL === 'function') {
      defaultBaseURL = async (): Promise<string> => {
        return baseURL(this.apiName, this.apiVersion)
      }
    } else if (typeof baseURL === 'string') {
      defaultBaseURL = async (): Promise<string> => {
        return baseURL
      }
    } else {
      defaultBaseURL = async (): Promise<string> => {
        const discoveryCache = ApiDiscoverySvcCache.getInstance(baseURL)
        try {
          return await discoveryCache.getBaseURL(
            this.apiName,
            this.apiVersion,
            authProvider,
            false, // ignoreCache
            true // remoteDiscovery
          )
        } catch (error) {
          console.warn(error)
          return await discoveryCache.getBaseURL(
            this.apiName,
            this.apiVersion,
            authProvider,
            true, // ignoreCache
            true // remoteDiscovery
          )
        }
      }
    }

    this.jarvisWebHttpInstance = new JarvisWebHttpClient({
      defaultBaseURL: defaultBaseURL,
      defaultAuthProvider: authProvider
    })
    this.xCorrelationId = xCorrelationId
  }

  protected defaultHeaders(): Record<string, string> {
    return typeof this.xCorrelationId === 'string'
      ? {
          'X-Correlation-ID': this.xCorrelationId
        }
      : {}
  }
}
