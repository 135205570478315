import styled from 'styled-components'
import tokens from '@veneer/tokens'

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px 24px;
  font-family: ${tokens.typography.family0};
  font-weight: 400;
`

export const StyledDescContainer = styled.div`
  && {
    font-size: ${tokens.typography.size2};
    font-weight: 400;
    font-family: ${tokens.typography.family0};
    color: ${tokens.color.gray9};
    line-height: ${tokens.typography.lineHeight3};
    padding: 6px 10px 24px 0;
    letter-spacing: 0.02em;
  }
`

export const StyledTextContainer = styled.div`
  && {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    padding: 0 10px;
    width: 100%;
    min-height: 400px;
    font-size: ${tokens.typography.size2};
    font-weight: 400;
    font-family: ${tokens.typography.family0};
    color: ${tokens.color.gray9};
    letter-spacing: 0.02em;

    && {
      @media only screen and (max-width: ${tokens.layout.smMax}) {
        min-height: 300px;
      }
    }
  }
`
