import { SubscriptionType } from '@/types/SubscriptionType'

export default function getSubscriptionTitleTextId(subType: string): string {
  switch (subType) {
    case SubscriptionType.INSTANT_INK:
      return 'subscriptionId'
    case SubscriptionType.HP_ONE:
      return 'planId'
    case SubscriptionType.IOT_PC:
      return 'planId'
    default:
      return 'subscriptionId'
  }
}
