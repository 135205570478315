import React from 'react'
import AppContext from './AppContext'
import appReducer, { initialState } from './appReducer'

const AppProvider = (props) => {
  const [state, dispatch] = React.useReducer(appReducer, initialState)
  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {props.children}
    </AppContext.Provider>
  )
}

export default AppProvider
