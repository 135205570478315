import styled from 'styled-components'
import tokens from '@veneer/tokens'

export const StyledDescContainer = styled.div`
  && {
    font-size: ${tokens.typography.size2};
    font-weight: 400;
    font-family: ${tokens.typography.family0};
    color: ${tokens.color.gray12};
    line-height: ${tokens.typography.lineHeight3};
    padding: 12px 10px 0 0;
    letter-spacing: 0.02em;
    margin-bottom: ${tokens.layout.size1};
  }
`

export const BillingItemsContainer = styled.div`
  && {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: ${tokens.typography.size9};
  }
  > div > div {
    border-radius: ${tokens.layout.cornerRadius4};
  }
`

export const BillingSectionContainer = styled.div`
  margin: ${tokens.layout.size4} ${tokens.layout.size4};
  font-family: ${tokens.typography.family0};
  font-weight: 400;
`

export const StyledTitle = styled.div`
  && {
    font-family: ${tokens.typography.family0};
    color: #737373;
    font-size: ${tokens.typography.size5};
    line-height: ${tokens.typography.lineHeight5};
    padding: 24px 0 0 0;
  }
`
export const StyledTextContainer = styled.div`
  && {
    font-size: ${tokens.typography.size2};
    font-weight: 400;
    font-family: ${tokens.typography.family0};
    color: ${tokens.color.gray9};
    line-height: ${tokens.typography.lineHeight3};
    padding: 12px 0;
    letter-spacing: 0.02em;
  }
`
