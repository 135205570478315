import React from 'react'
import { PgsForm } from '../PgsForm'
import { StyledStep } from '../Shared/styles'

export const StepTwo = () => {
  return (
    <StyledStep>
      <PgsForm />
    </StyledStep>
  )
}
