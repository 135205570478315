import React, { useEffect, useMemo, useState } from 'react'
import {
  BillingSectionContainer,
  StyledTitle,
  StyledDescContainer,
  BillingItemsContainer
} from './styles'
import Card from '@veneer/core/dist/scripts/card'
import useGetText from '@/hooks/useGetText'
import Images from '../../assets/images'
import Skeleton from '../Skeleton'
import PaymentMethod from './PaymentMethod'
import HpOnePaymentMethod from './HpOnePaymentMethod'
import usePaymentMethodsInfo from '@/hooks/usePaymentMethodsInfo'
import { PaymentMethodType } from '@/types/PaymentMethodType'
import { SubscriptionInfo } from '@/types/SubscriptionInfo'
import { mblEntityType, SubscriptionEntity } from '@/types/SubscriptionEntity'
import { HpOneSubsEntityState } from '@/types/HpOneSubsEntityState'
import { instantInkStates } from '@/utils/instantInkStates'
import {
  StyledIconContainer,
  StyledTextContainer
} from './NoBillingDataContent/styles'
import ErrorMessage from '../ErrorMessage'
import AddPaymentMethod from '@/components/Billing/AddPaymentMethod'
import { useAppContext } from '@/context/AppContext'
import useBillingInfo from '@/hooks/useBillingInfo'
import { EDelinquencyNotification } from '@/types/pendingChanges'
const Billing = ({
  expirationStatus
}: {
  expirationStatus?: EDelinquencyNotification
}) => {
  const [hpOneBillingInfo, setHpOneBillingInfo] = useState(null)
  const getText = useGetText('billing')
  const {
    instantInkSubscriptionInfo,
    account,
    subscriptionInfo: {
      data: subscriptionInfo,
      isFetching: subscriptionInfoIsFetching
    }
  } = useAppContext().state

  const {
    info: {
      data: instantInkBillingInfo,
      isFetching: instantInkBillingIsFetching,
      error: instantInkBillingError
    }
  } = useBillingInfo()

  const {
    info: { data: billingInfo, isFetching: billingIsFetching },
    forceRefresh
  } = usePaymentMethodsInfo(subscriptionInfo?.[0]?.paymentMethodId)

  useEffect(() => {
    if (
      !billingIsFetching &&
      !subscriptionInfoIsFetching &&
      billingInfo &&
      subscriptionInfo
    ) {
      setHpOneBillingInfo({
        ...billingInfo,
        payment_methods: billingInfo.payment_method_details
      })
    }
  }, [
    billingIsFetching,
    subscriptionInfoIsFetching,
    billingInfo,
    subscriptionInfo
  ])

  const showCreditCard =
    hpOneBillingInfo?.payment_method_details?.card_type ||
    (!!instantInkBillingInfo?.creditCard?.cardTypeKey &&
      instantInkSubscriptionInfo?.data?.instantInkSubscriptions?.length > 0)

  const showPayPal =
    instantInkBillingInfo?.paymentType === PaymentMethodType.pay_pal &&
    instantInkSubscriptionInfo?.data?.instantInkSubscriptions?.length > 0

  const showBilling = showCreditCard || showPayPal || !!instantInkBillingError
  const instantInkState = [
    instantInkStates.SUBSCRIBED_NO_PENS,
    instantInkStates.SUBSCRIBED_NO_PRINTER,
    instantInkStates.SUBSCRIBED,
    instantInkStates.INITIATED_UNSUBSCRIBE,
    instantInkStates.UNSUBSCRIBED
  ]

  const isAddPayment =
    !showBilling &&
    (instantInkSubscriptionInfo?.data?.instantInkSubscriptions?.find(
      ({ state }) =>
        state === HpOneSubsEntityState.ACTIVE ||
        instantInkState.includes(state as instantInkStates)
    ) ||
      // to be typed properly once there is a definitive version of BE SSS response.
      subscriptionInfo[0]?.entities.find(({ state }) => state === 'active'))
  useEffect(() => {
    forceRefresh()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account?.data])

  const cancellingStates = [HpOneSubsEntityState.INACTIVE]
  const isCancelling = (entry: SubscriptionInfo): boolean => {
    return !entry?.entities.find(
      (entry) => isHpOneEntity(entry) && !cancellingStates.includes(entry.state)
    )
  }

  const isHpOneEntity = (entity: SubscriptionEntity): boolean => {
    return (
      entity.entityType === mblEntityType.pc ||
      entity.entityType === mblEntityType.printer ||
      entity.entityType === mblEntityType.chromebook
    )
  }

  const skeletonContent = useMemo(
    () =>
      instantInkBillingIsFetching &&
      billingIsFetching && (
        <BillingSectionContainer>
          <Skeleton width="60vw" height="5vh" />
        </BillingSectionContainer>
      ),
    [instantInkBillingIsFetching, billingIsFetching]
  )

  const billingHeaderContent = useMemo(
    () =>
      (showBilling || isAddPayment) && (
        <>
          <StyledTitle role="heading" aria-level="3">
            {getText('yourBilling')}
          </StyledTitle>
          <StyledDescContainer>
            {getText('paymentInfoOnFileMsg')}
          </StyledDescContainer>
        </>
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showBilling, getText]
  )

  const NoBillingDataContent = useMemo(
    () =>
      !showBilling &&
      !isAddPayment && (
        <BillingSectionContainer>
          <StyledIconContainer>
            <img src={Images.billing} alt="title icon" />
          </StyledIconContainer>
          <StyledTitle>{getText('header')}</StyledTitle>
          <StyledTextContainer>
            {getText('noBillingInfoMsg')}
          </StyledTextContainer>
        </BillingSectionContainer>
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showBilling, getText]
  )

  const instantInkBillingErrorContent = useMemo(
    () =>
      !!instantInkBillingError && (
        <ErrorMessage image={Images.instant_ink} text={`tryAgainLater`} />
      ),
    [instantInkBillingError]
  )

  return (
    <>
      {instantInkBillingIsFetching && billingIsFetching && (
        <Card
          data-testid="instantInkBillingIsFetching-card"
          appearance="dropShadow"
          content={skeletonContent}
          className="card-box"
        />
      )}
      <>
        {NoBillingDataContent}
        {billingHeaderContent}
        <BillingItemsContainer>
          {showCreditCard && subscriptionInfo && (
            <HpOnePaymentMethod
              expirationStatus={expirationStatus}
              isDisabled={isCancelling(subscriptionInfo[0])}
            />
          )}
          {instantInkSubscriptionInfo?.data?.instantInkSubscriptions?.length >
            0 &&
            (showCreditCard || showPayPal) && <PaymentMethod />}
          {!showCreditCard && !showPayPal && isAddPayment && (
            <AddPaymentMethod />
          )}
          {instantInkBillingErrorContent}
        </BillingItemsContainer>
      </>
    </>
  )
}

export default Billing
