import React from 'react'
import { ShellProvider } from '@/context/shellContext'
import setEnvironmentVariables from '@/helpers/setEnvironmentVariables'
import { getStack } from '@/helpers/getStack'
import { MfePropsType } from '@/types/mfeProps'
import { ApolloProvider } from '@apollo/client'
import useApolloClient from '@/hooks/useApolloClient/useApolloClient'
import AppProvider from '@/context/AppProvider'
import BillingAndShipping from '../BillingAndShipping'
import LaunchDarkly from '../LaunchDarkly/LaunchDarkly'

const TabContent = (props: MfePropsType) => {
  setEnvironmentVariables(getStack(props.stack))
  const client = useApolloClient(props)
  return (
    <AppProvider>
      <ShellProvider value={props}>
        <LaunchDarkly>
          <ApolloProvider client={client}>
            <BillingAndShipping />
          </ApolloProvider>
        </LaunchDarkly>
      </ShellProvider>
    </AppProvider>
  )
}

export default TabContent
