import styled from 'styled-components'
import tokens from '@veneer/tokens'
interface StyledExpStatus {
  expirationStatus?: boolean
}

export const StyledCCImageContainer = styled.div`
  && {
    img {
      width: 40px;
      height: ${tokens.layout.size7};
    }
    padding: 0 0 2px 0;
    display: flex;
    flex-direction: column;
    margin-bottom: ${tokens.layout.size1};
  }
`
export const StyledDetailsInfo = styled.div`
  && {
    font-family: ${tokens.typography.family0};
    font-size: ${tokens.typography.size1};
    line-height: 20px;
    letter-spacing: 0.02em;
    color: ${tokens.color.gray12};
  }
`

const statusToColorMap = {
  about_to_expire: '#b2600c',
  expired: '#CC2C00'
}
export const PaymentIconInfo = styled.div<StyledExpStatus>`
  && {
    color: ${(props) => statusToColorMap[props.expirationStatus]};
  }
  .iconInfo {
    position: relative;
    top: 3px;
    color: ${(props) => statusToColorMap[props.expirationStatus]};
  }
`
