import React, { useContext } from 'react'
import { Container, NotificationTitle, Wrapper } from './styles'
import WarningIcon from '@veneer/core/dist/scripts/icons/icon_warning_alt'
import useGetText from '@/hooks/useGetText'
import { WarningContext } from '@/context/WarningContext'

const HeaderNotification = () => {
  const getText = useGetText()
  const { warningType } = useContext(WarningContext)
  return warningType === 'noWarning' ? (
    <></>
  ) : (
    <Container>
      <div>
        <WarningIcon
          data-testid={`${warningType}_iconTest`}
          size={24}
          customStyle={{
            fill: '#d06702'
          }}
        />
      </div>
      <Wrapper>
        <NotificationTitle>
          {getText(`${warningType}Subtitle`)}
        </NotificationTitle>
      </Wrapper>
    </Container>
  )
}

export default HeaderNotification
