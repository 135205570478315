import React, { FC } from 'react'
import ChevronLeft from '@veneer/core/dist/scripts/icons/icon_chevron_left'
import Button, { ButtonProps } from '@veneer/core/dist/scripts/button'
import { useGetText } from '../../hooks'
import { StyledBackButton, StyledButtonContent } from './styles'

export const BackButton: FC<ButtonProps> = ({ onClick }) => {
  const getText = useGetText()

  return (
    <StyledBackButton>
      <Button
        data-testid="previous-screen-button"
        data-analyticsid="previous-screen-button"
        appearance="ghost"
        onClick={onClick}
      >
        <StyledButtonContent>
          <ChevronLeft color="colorHpBlue6" size={32} />
          <span>{getText('billing_address.previous_screen')}</span>
        </StyledButtonContent>
      </Button>
    </StyledBackButton>
  )
}
