import React from 'react'
import { BillingTitle } from '../BillingTitle'
import { BillingFlow } from '../BillingFlow'

export const BillingFormContent = () => {
  return (
    <div>
      <BillingTitle />
      <BillingFlow />
    </div>
  )
}
