import { CreditCardInfo } from '@/types/CreditCardInfo'
import Images from '@/assets/images'

const CardList = {
  alipay: {
    cardName: 'Alipay',
    cardImage: Images.alipay,
    testid: 'cardImg-Alipay'
  },
  american_express: {
    cardName: 'Amex',
    cardImage: Images.amex,
    testid: 'cardImg-Amex'
  },
  diners: {
    cardName: 'Diners',
    cardImage: Images.diners,
    testid: 'cardImg-Diners'
  },
  discover: {
    cardName: 'Discover',
    cardImage: Images.discover,
    testid: 'cardImg-Discover'
  },
  elo: { cardName: 'Elo', cardImage: Images.elo, testid: 'cardImg-Elo' },
  hiper: {
    cardName: 'Hiper',
    cardImage: Images.hiper,
    testid: 'cardImg-Hiper'
  },
  hipercard: {
    cardName: 'Hipercard',
    cardImage: Images.hipercard,
    testid: 'cardImgHipercard'
  },
  jcb: { cardName: 'Jcb', cardImage: Images.jcb, testid: 'cardImg-Jcb' },
  maestro: {
    cardName: 'Maestro',
    cardImage: Images.maestro,
    testid: 'cardImg-Maestro'
  },
  mastercard: {
    cardName: 'MasterCard',
    cardImage: Images.masterCard,
    testid: 'cardImg-Mastercard'
  },
  mir: { cardName: 'Mir', cardImage: Images.mir, testid: 'cardImg-Mir' },
  paypal: {
    cardName: 'Paypal',
    cardImage: Images.paypal,
    testid: 'cardImg-Paypal'
  },
  unionpay: {
    cardName: 'Unionpay',
    cardImage: Images.unionpay,
    testid: 'cardImg-Unionpay'
  },
  visa: { cardName: 'Visa', cardImage: Images.visa, testid: 'cardImg-Visa' }
}

export default function getHpOnePaymentCardInfo(
  creditCardType: string
): CreditCardInfo {
  return (
    CardList[creditCardType] ?? {
      cardName: creditCardType,
      cardImage: Images.generic,
      testid: 'cardImgGeneric'
    }
  )
}
