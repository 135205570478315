import AddressInfo from '@/components/Shipping/AddressInfo'
import { useAppContext } from '@/context/AppContext'
import getSubscriptionShippingAddressInfo from '@/helpers/getSubscriptionShippingAddressInfo'
import useGetText from '@/hooks/useGetText'
import useInstantInkDeviceAddress from '@/hooks/useInstantInkDeviceAddress'
import useShippingInfo from '@/hooks/useShippingInfo'
import { HpOneSubsEntityState } from '@/types/HpOneSubsEntityState'
import { mblEntityType, SubscriptionEntity } from '@/types/SubscriptionEntity'
import { SubscriptionType } from '@/types/SubscriptionType'
import Card from '@veneer/core/dist/scripts/card'
import React, { useContext, useEffect } from 'react'
import Images from '../../assets/images'
import Skeleton from '../Skeleton'
import {
  ShippingAddressContainer,
  ShippingAddressItemContainer,
  ShippingSectionContainer,
  StyledIconContainer,
  StyledTextContainer,
  StyledTitle
} from './styles'
import ErrorMessage from '../ErrorMessage'
import { WarningContext } from '@/context/WarningContext'
import { SubscriptionInfo } from '@/types/SubscriptionInfo'
import { getMainEntity } from '@/helpers/getMainEntity'

const Shipping = () => {
  const { warningType, setWarningType } = useContext(WarningContext)
  const [hasShippingInfo, setHasShippingInfo] = React.useState(false)
  const [hasInstantInkInfo, setHasInstantInkInfo] = React.useState(false)
  const getText = useGetText('shipping')
  const {
    instantInkSubscriptionInfo,
    subscriptionInfo
  } = useAppContext()?.state
  const {
    info: {
      data: ShippingInfo,
      isFetching: hpOneIsFetching,
      error: hpOneError
    },
    forceRefresh
  } = useShippingInfo()
  //Get HP_One subscriptions (MBLC)
  const mappedSubscriptionInfos =
    subscriptionInfo?.data &&
    getSubscriptionShippingAddressInfo(ShippingInfo, subscriptionInfo.data)

  //Get shippingaddresses of IntantInk subscriptions
  const cloudIdentifiers = instantInkSubscriptionInfo?.data?.instantInkSubscriptions?.map(
    (subscription) => subscription.printerCloudIdentifier
  )

  const {
    info: {
      data: instantInkDeviceAddressInfo,
      isFetching: instantInkDeviceAddressInfoIsFetching
    },
    forceRefresh: instantInkDeviceAddressInfoRefresh
  } = useInstantInkDeviceAddress(cloudIdentifiers)

  useEffect(() => {
    setHasShippingInfo(
      !!ShippingInfo &&
        ShippingInfo.length > 0 &&
        !!mappedSubscriptionInfos &&
        mappedSubscriptionInfos?.length > 0
    )
  }, [ShippingInfo, mappedSubscriptionInfos])

  useEffect(() => {
    setHasInstantInkInfo(
      instantInkSubscriptionInfo?.data?.instantInkSubscriptions?.length > 0 &&
        instantInkDeviceAddressInfo?.length > 0
    )
  }, [instantInkSubscriptionInfo, instantInkDeviceAddressInfo])

  const onAddressSaved = async () => {
    forceRefresh()
    instantInkDeviceAddressInfoRefresh()

    if (warningType === 'shippingWarning') {
      setWarningType('noWarning')
    } else if (warningType === 'shippingAndBillingWarning') {
      setWarningType('billingWarning')
    }
  }

  const cancellingStates = [
    HpOneSubsEntityState.DEACTIVATING,
    HpOneSubsEntityState.INACTIVE
  ]

  const isSubscribed = (entry: SubscriptionInfo): boolean => {
    const entity = [getMainEntity(entry.entities)]
    return !entity.find(
      (item) =>
        isHpOneEntity(item) && item.state !== HpOneSubsEntityState.ACTIVE
    )
  }

  const isCancelling = (entry: SubscriptionInfo): boolean => {
    const entity = [getMainEntity(entry.entities)]
    return !entity.find(
      (item) => isHpOneEntity(item) && !cancellingStates.includes(item.state)
    )
  }

  const isHpOneEntity = (entry: SubscriptionEntity): boolean => {
    return (
      entry.entityType === mblEntityType.pc ||
      entry.entityType === mblEntityType.printer ||
      entry.entityType === mblEntityType.chromebook
    )
  }

  const withAddressHeader = (
    <>
      <StyledTitle role="heading" aria-level="2">
        {getText('yourAddress')}
      </StyledTitle>
      <StyledTextContainer>
        {getText('withShippingInfoMsg')}
      </StyledTextContainer>
    </>
  )

  const hpOneContent =
    hasShippingInfo &&
    mappedSubscriptionInfos?.map((subitem, index) => {
      return (
        <ShippingAddressItemContainer key={index}>
          <AddressInfo
            shippingAddress={subitem?.subscriptionEntity?.shippingAddress}
            friendlysubscriptionId={
              subscriptionInfo?.data[0]?.friendlySubscriptionId
            }
            subscriptionId={subitem?.subscriptionId}
            entityType={SubscriptionType.HP_ONE}
            enableEdit={isSubscribed(subitem)}
            onAddressSaved={onAddressSaved}
            isDisabled={isCancelling(subitem)}
          />
        </ShippingAddressItemContainer>
      )
    })

  const hpOneErrorContent = hpOneError && (
    <ShippingAddressItemContainer>
      <ErrorMessage
        image={Images.hp_all_in_plan}
        text={`tryAgainLater`}
        data-testid="error-message"
      />
    </ShippingAddressItemContainer>
  )
  const instantInkContent =
    hasInstantInkInfo &&
    !instantInkDeviceAddressInfoIsFetching &&
    instantInkSubscriptionInfo?.data?.instantInkSubscriptions?.map(
      (subitem, index) => {
        return (
          <ShippingAddressItemContainer key={index}>
            <AddressInfo
              shippingAddress={instantInkDeviceAddressInfo?.find(
                (c) => c.tenantId === subitem.printerCloudIdentifier
              )}
              friendlysubscriptionId={subitem?.accountIdentifier}
              subscriptionId={subitem?.accountIdentifier}
              entityType={SubscriptionType.INSTANT_INK}
              onAddressSaved={onAddressSaved}
              cloudId={subitem.printerCloudIdentifier}
            />
          </ShippingAddressItemContainer>
        )
      }
    )

  const instantInkErrorContent = instantInkSubscriptionInfo?.error && (
    <ShippingAddressItemContainer>
      <ErrorMessage image={Images.instant_ink} text={`tryAgainLater`} />
    </ShippingAddressItemContainer>
  )

  const skeleton = hpOneIsFetching && instantInkSubscriptionInfo?.isFetching && (
    <ShippingAddressItemContainer>
      <Skeleton width="60vw" height="5vh" />
    </ShippingAddressItemContainer>
  )

  const addressContent = (hasShippingInfo ||
    hpOneError ||
    hasInstantInkInfo ||
    !instantInkErrorContent) && (
    <>
      <StyledTextContainer>{withAddressHeader}</StyledTextContainer>
      <ShippingAddressContainer>
        {hpOneContent}
        {hpOneErrorContent}
        {instantInkContent}
        {instantInkErrorContent}
      </ShippingAddressContainer>
    </>
  )

  const cardContent = !hasShippingInfo &&
    !hpOneError &&
    !hasInstantInkInfo &&
    !instantInkErrorContent && (
      <>
        <StyledIconContainer>
          <img src={Images.shipping} alt="title icon" />
        </StyledIconContainer>
        <StyledTitle>{getText('header')}</StyledTitle>
        <StyledTextContainer>
          {getText('noShippingInfoMsg')}
        </StyledTextContainer>
      </>
    )

  return (
    <ShippingSectionContainer>
      {skeleton}
      {addressContent}
      {cardContent && <Card content={cardContent} className="card-box" />}
    </ShippingSectionContainer>
  )
}

export default Shipping
