import { JarvisAuthProvider } from '@jarvis/web-http'
import InfoDatas from 'src/types/InfoDatas'
import { Stack, UserMgtSvcClientv3 } from '@jarvis/web-stratus-client'

export default class UserMgtSvcClient {
  stack: Stack
  authProvider: JarvisAuthProvider

  constructor(stack: Stack, authProvider: JarvisAuthProvider) {
    this.stack = stack
    this.authProvider = authProvider
  }

  async getCurrentActiveUser(): Promise<InfoDatas['userInfo']> {
    const userMgtSvcClient = new UserMgtSvcClientv3(
      this.stack,
      this.authProvider
    )
    const result = await userMgtSvcClient.getCurrentActiveUser()

    return result?.data
  }
}
