import usePaymentMethodClient from '../usePaymentMethodClient'
import useDataFetcher from '../useDataFetcher'

const usePaymentMethodsInfo = (paymentMethodId?: string) => {
  const paymentMethodClient = usePaymentMethodClient()

  return useDataFetcher(
    'paymentMethodsInfo',
    paymentMethodId && paymentMethodClient?.getPaymentMethodsData
      ? paymentMethodClient.getPaymentMethodsData.bind(
          paymentMethodClient,
          paymentMethodId
        )
      : null
  )
}

export default usePaymentMethodsInfo
