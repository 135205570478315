import styled from 'styled-components'
import tokens from '@veneer/tokens'

export const StyledPayPalLogo = styled.div`
  && {
    img {
      width: 80px;
      height: 20px;
    }
  }
`
export const StyledCardHolder = styled.div`
  && {
    font-family: ${tokens.typography.family0};
    font-size: ${tokens.typography.size1};
    line-height: 20px;
    letter-spacing: 0.02em;
  }
`
export const StyledDetailsInfo = styled.div`
  && {
    font-family: ${tokens.typography.family0};
    font-size: ${tokens.typography.size1};
    line-height: 20px;
    letter-spacing: 0.04em;
  }
`
