import useUserManagementInfo from '../useUserManagementInfo'

const useUserInfo = () => {
  const {
    info: { data: userManagementInfo }
  } = useUserManagementInfo()

  const userName = userManagementInfo
    ? userManagementInfo.givenName.charAt(0).toUpperCase() +
      userManagementInfo.givenName.slice(1) +
      ' ' +
      userManagementInfo.familyName.charAt(0).toUpperCase() +
      userManagementInfo.familyName.slice(1)
    : ''

  return { userName }
}

export default useUserInfo
