import { useEffect } from 'react'
import { useBillingFormState } from './useBillingFormState'
import { useDispatch } from './useDispatch'
import { fetchPaymentMethodSettingsAction } from '../actions'

export const usePaymentMethodSettingsLoading = () =>
  useBillingFormState().paymentMethodSettingsLoading

export const usePaymentMethodSettingsError = () =>
  useBillingFormState().paymentMethodSettingsError

let fetching = false

export const usePaymentMethodSettings = () => {
  const {
    paymentMethodSettings,
    paymentMethodSettingsLoading,
    paymentMethodSettingsError
  } = useBillingFormState()
  const dispatch = useDispatch()

  useEffect(() => {
    if (
      paymentMethodSettings === undefined &&
      !paymentMethodSettingsLoading &&
      !paymentMethodSettingsError &&
      !fetching
    ) {
      ;(async () => {
        try {
          fetching = true
          await dispatch(fetchPaymentMethodSettingsAction())
        } catch {
          // do nothing
        } finally {
          fetching = false
        }
      })()
    }
  }, [
    paymentMethodSettings,
    paymentMethodSettingsLoading,
    paymentMethodSettingsError,
    dispatch
  ])

  return paymentMethodSettings
}
