import styled from 'styled-components'
import { tokens } from '@veneer/tokens'

export const Container = styled.div`
  display: flex;
  padding: 0px 40px;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  margin-top: 40px;
  font-family: ${({ theme }) => theme.fontTextRegular};

  .title {
    font-size: ${tokens.typography.size5};
    line-height: 32px;
  }
  .subtitle {
    font-size: ${tokens.typography.size2};
    line-height: ${tokens.typography.lineHeight2};
    margin-top: ${tokens.layout.size2};
  }
  .reload-button {
    margin-top: ${tokens.layout.size5};
  }
`
