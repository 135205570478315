import React from 'react'
import Button from '@veneer/core/dist/scripts/button'
import {
  useGetText,
  useOnCancel,
  MOBILE_CONTAINER,
  TABLET_PORTRAIT_CONTAINER,
  useContainerSize
} from '../../hooks'
import WarningIcon from '@veneer/core/dist/scripts/icons/icon_warning_alt'
import { StyledErrorMessagePage, StyledErrorPageContent } from './styles'
import { StyledButtonSection } from '../Shared/styles'

export type ErrorMessageProps = {
  onRetry: () => void
}

export const ErrorMessagePage = ({ onRetry }: ErrorMessageProps) => {
  const getText = useGetText()
  const onCancel = useOnCancel()
  const containerSize = useContainerSize()

  return (
    <StyledErrorMessagePage>
      <StyledErrorPageContent>
        <WarningIcon size={48} color={'colorOrange6'} />
        {getText('error_message.something_went_wrong')}
      </StyledErrorPageContent>
      <StyledButtonSection $full-width>
        <Button
          appearance="secondary"
          data-testid="cancel-button"
          onClick={onCancel}
          expanded={
            containerSize === MOBILE_CONTAINER ||
            containerSize === TABLET_PORTRAIT_CONTAINER
          }
        >
          {getText('error_message.cancel')}
        </Button>
        <Button
          appearance="primary"
          data-testid="retry-button"
          onClick={onRetry}
          expanded={
            containerSize === MOBILE_CONTAINER ||
            containerSize === TABLET_PORTRAIT_CONTAINER
          }
        >
          {getText('error_message.retry')}
        </Button>
      </StyledButtonSection>
    </StyledErrorMessagePage>
  )
}
