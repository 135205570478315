export enum AccountType {
  Consumer = 'consumer',
  Business = 'business'
}

export enum Action {
  Add = 'add',
  Update = 'update',
  None = 'none'
}

export interface TaxpayerDetails {
  companyName: string
  accountType: AccountType
  taxId: string
  nonProfitTaxId: string
  pecEmailOrRecipientCode?: string
  lockedFields: string
  allowEditingTaxId: boolean
}
