import { useFlags } from 'launchdarkly-react-client-sdk'
import useAccount from '../useAccount'

const useIsInstantInkEnabled = () => {
  const { loadInstantInkShippingBilling } = useFlags()

  const { info } = useAccount()

  return loadInstantInkShippingBilling?.countries?.find(
    (country: string) => country === info.data?.regionId || country === '*'
  )
}

export default useIsInstantInkEnabled
