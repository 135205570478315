import styled from 'styled-components'
import tokens from '@veneer/tokens'

export const Container = styled.div`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  background-color: rgba(208, 103, 2, 0.1);
  padding: 12px 40px;
  height: fit-content;
  > svg {
    color: #d06702;
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-left: 12px;
`

export const NotificationTitle = styled.h2`
  font-size: ${tokens.typography.size1};
  font-weight: 700;
  line-height: ${tokens.typography.lineHeight2};
  margin-bottom: 4px;
  color: ${tokens.color.gray12};
  margin: 0;
  font-family: ${tokens.typography.family0};
`
