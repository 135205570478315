/* eslint-disable @typescript-eslint/no-unused-vars */
import { AxiosResponse } from 'axios'
import { PHCTokenPayload, PickupPHCTokenResponse } from '../../types'
import { MockService } from './mockService'
import { IPaymentMethodService } from '../../lib/paymentMethodService'
import { pickupPHCTokenResponseFixture } from '../fixtures'

export class MockPaymentMethodService
  extends MockService
  implements IPaymentMethodService
{
  pickupPHCToken(
    business_unit_guid: string,
    country: string,
    phcTokenInfo: PHCTokenPayload
  ): Promise<AxiosResponse<PickupPHCTokenResponse>> {
    return this.resolve({
      data: pickupPHCTokenResponseFixture(),
      status: 200,
      statusText: 'ok',
      headers: {},
      config: {}
    })
  }
}
