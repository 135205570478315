export const HpOneSubsEntityState = {
  INACTIVE: 'inactive',
  DEACTIVATING: 'deactivating',
  PAUSED: 'paused',
  PENDING: 'pending',
  UPDATING: 'updating',
  ACTIVE: 'active',
  ERROR: 'error',
  SUSPENDED: 'suspended'
}
