import React from 'react'
import {
  ErrorItemContainer,
  StyledIconError,
  StyledTextContainer
} from './styles'
import Card from '@veneer/core/dist/scripts/card'
import useGetText from '@/hooks/useGetText'
//import Images from '@/assets/images'

export type ErrorProps = {
  image: string
  text: string
}

const ErrorMessage = (props: ErrorProps) => {
  const { image, text } = props
  const getText = useGetText('errorMessage')

  const errorMessageContent = (
    <ErrorItemContainer>
      <StyledIconError>
        <img src={image} alt="HP All In Plan" />
      </StyledIconError>
      <StyledTextContainer>{getText(text)}</StyledTextContainer>
    </ErrorItemContainer>
  )

  return (
    <Card
      content={errorMessageContent}
      className="card-box"
      customStyle={{ borderRadius: 8 }}
    />
  )
}

export default ErrorMessage
