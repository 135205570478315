import Modal from '@veneer/core/dist/scripts/modal/modal'
import tokens from '@veneer/tokens'
import styled from 'styled-components'

interface StyledAddressInfoProps {
  isDisabled?: boolean
}

export const StyledAddressItemContainer = styled.div`
  && {
    padding: ${tokens.layout.size4};
    margin-bottom: 5px;
    min-height: 240px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`
export const ModalContentContainer = styled.div`
  & .loading {
    width: auto;
    padding-top: 0;
    padding-bottom: 30px;
  }

  & .shipping-content .shipping-fields {
    width: auto;
  }
`
export const StyledModal = styled(Modal)`
  div.vn-modal--content {
    @media (min-width: 991px) {
      width: calc(100% - 13px - 13px);
      max-width: 900px;
    }
  }
  .vn-modal--footer {
    text-align: right;
  }
`

export const StyledButtonContainer = styled.div`
  && {
    font-weight: 400;
    padding: ${tokens.layout.size1} 0 0 0;
    font-family: ${tokens.typography.family0};
    font-size: 14px;
    a {
      cursor: pointer;
      color: rgba(0, 118, 173, 1);
    }
  }
`
export const StyledIconContainer = styled.div`
  && {
    display: flex;
    font-family: ${tokens.typography.family0};
    font-weight: 400;
    flex-direction: column;
    padding: 10px 0 10px 0;
    margin-bottom: ${tokens.layout.size1};
    img {
      width: ${tokens.layout.size6};
      height: ${tokens.layout.size6};
    }
  }
`

export const StyledTitle = styled.div<StyledAddressInfoProps>`
  && {
    font-family: ${tokens.typography.family0};
    line-height: 24px;
    font-weight: 400;
    word-wrap: break-word;
    color: ${(props) =>
      props.isDisabled ? tokens.color.highContrastGray : tokens.color.gray9};
  }
`
export const StyledContent = styled.div<StyledAddressInfoProps>`
  && {
    font-family: ${tokens.typography.family0};
    font-size: ${tokens.typography.size1};
    line-height: ${tokens.typography.lineHeight2};
    font-weight: 400;
    color: ${(props) =>
      props.isDisabled ? tokens.color.highContrastGray : tokens.color.gray9};
    span {
      word-break: break-word;
    }
  }
`

export const StyledDisabled = styled.span`
  && {
    font-family: ${tokens.typography.family0};
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: ${(props) =>
      props.isDisabled ? tokens.color.highContrastGray : '#767676'};
  }
`
