import { StratusClient } from './stratusClient'
import { AxiosResponse } from 'axios'
import { PHCTokenPayload, PickupPHCTokenResponse } from '../types'

export interface IPaymentMethodService {
  pickupPHCToken(
    business_unit_guid: string,
    country: string,
    phcTokenInfo: PHCTokenPayload
  ): Promise<AxiosResponse<PickupPHCTokenResponse>>
}

export class PaymentMethodService
  extends StratusClient
  implements IPaymentMethodService
{
  apiName = 'ws-hp.com/paymentmethodsvc'
  apiVersion = '1'
  phcTokenPublisherEndpoint = '/phc_token_publisher'

  pickupPHCToken(
    businessUnitGuid: string,
    country: string,
    phcTokenInfo: PHCTokenPayload
  ): Promise<AxiosResponse<PickupPHCTokenResponse>> {
    return this.jarvisWebHttpInstance.post({
      url: `${this.phcTokenPublisherEndpoint}/${businessUnitGuid}/${country}`,
      headers: this.defaultHeaders(),
      data: phcTokenInfo
    })
  }
}
