export const getRequiredFields = (showStateDropdown: boolean) => {
  const requiredFields = [
    'firstName',
    'lastName',
    'address',
    'address2',
    'city',
    'postalCode'
  ]
  if (showStateDropdown) {
    requiredFields.push('state')
  }

  return { requiredFields }
}
