import { AssetsProvider } from '@jarvis/web-assets-provider'
import { BillingInfo, NotificationType } from '@/types/BillingInfo'
import { ShippingAddress } from '@/types/ShippingAddress'
import { Account } from '@/types/Account'

export enum ActionsType {
  SET_IS_LOADING = 'SET_IS_LOADING',
  SET_IS_SUBMITTED = 'SET_IS_SUBMITTED',
  SET_BILLINGINFO = 'SET_BILLINGINFO',
  SET_SHIPPINGINFO = 'SET_SHIPPINGINFO',
  SET_ACCOUNT = 'SET_ACCOUNT',
  SET_ERROR = 'SET_ERROR',
  SET_SUCCESS_FETCH = 'SET_SUCCESS_FETCH',
  SET_IS_FETCHING = 'SET_IS_FETCHING',
  SET_ERROR_FETCH = 'SET_ERROR_FETCH',
  SET_ASSETS_PROVIDER = 'SET_ASSETS_PROVIDER',
  SET_BILLING_FORM = 'SET_BILLING_FORM',
  SET_INLINE_NOTIFICATION = 'SET_INLINE_NOTIFICATION'
}

export type Actions = {
  type: ActionsType
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any
}

export const setIsLoadingAction = (data: boolean): Actions => ({
  type: ActionsType.SET_IS_LOADING,
  data
})

export const setIsSubmittedAction = (data: boolean): Actions => ({
  type: ActionsType.SET_IS_SUBMITTED,
  data
})

export const setBillingInfo = (data: BillingInfo): Actions => ({
  type: ActionsType.SET_BILLINGINFO,
  data
})

export const setShippingInfo = (data: ShippingAddress[]): Actions => ({
  type: ActionsType.SET_SHIPPINGINFO,
  data
})

export const setAccount = (data: Account): Actions => ({
  type: ActionsType.SET_ACCOUNT,
  data
})

export const setErrorAction = (data: boolean): Actions => ({
  type: ActionsType.SET_ERROR,
  data
})
export const setBillingForm = (data: boolean): Actions => ({
  type: ActionsType.SET_BILLING_FORM,
  data
})

type ReturnSetAssetsProvider = {
  type: ActionsType.SET_ASSETS_PROVIDER
  data: AssetsProvider
}

export const setInlineNotification = (data: NotificationType): Actions => ({
  type: ActionsType.SET_INLINE_NOTIFICATION,
  data
})

export const setAssetsProvider = (
  assetsProvider: AssetsProvider
): ReturnSetAssetsProvider => ({
  type: ActionsType.SET_ASSETS_PROVIDER,
  data: assetsProvider
})
