import useShippingClient from '../useShippingClient'
import useDataFetcher from '../useDataFetcher'

const useInstantInkDeviceAddress = (cloudIdentifiers: string[]) => {
  const shippingClient = useShippingClient()
  return useDataFetcher(
    'instantInkDeviceAddressInfo',
    cloudIdentifiers && shippingClient?.getInstantInkShippingAddresses
      ? shippingClient.getInstantInkShippingAddresses.bind(
          shippingClient,
          cloudIdentifiers
        )
      : null
  )
}

export default useInstantInkDeviceAddress
