import AssetsProviderFactory from '../assets/AssetsProviderFactory'
import {
  BillingAddress,
  BillingFlowSteps,
  BillingFormAction,
  BillingFormActionType,
  BillingFormState,
  PaymentMethodInfo
} from '../types'
import { PaymentControllerService } from '../lib/paymentControllerService'
import { PaymentMethodService } from '../lib/paymentMethodService'
import { AddressService } from '../lib/addressService'
import { MockPaymentControllerService } from '../mock/lib/mockPaymentControllerService'
import { MockPaymentMethodService } from '../mock/lib/mockPaymentMethodService'
import { MockAddressService } from '../mock/lib/mockAddressService'
import { AddressValidator } from '../lib/addressValidator'
import { BillingFormProps } from '../components/BillingForm/billingForm'

export const billingFormInitialState = ({
  subscriptionId,
  country,
  language,
  nativeApp,
  virtualKeyboard,
  stack,
  baseURLProvider,
  authProvider,
  xCorrelationId,
  mockStratus,
  onSave,
  onUpdate,
  onCancel,
  trackClickEvent
}: BillingFormProps): BillingFormState => ({
  assetsProvider: AssetsProviderFactory.create(language, country),
  paymentControllerService: mockStratus
    ? new MockPaymentControllerService()
    : new PaymentControllerService(
        baseURLProvider || stack,
        authProvider,
        xCorrelationId
      ),
  paymentMethodService: mockStratus
    ? new MockPaymentMethodService()
    : new PaymentMethodService(
        baseURLProvider || stack,
        authProvider,
        xCorrelationId
      ),
  addressService: mockStratus
    ? new MockAddressService()
    : new AddressService(
        baseURLProvider || stack,
        authProvider,
        xCorrelationId
      ),
  country,
  language,
  nativeApp,
  virtualKeyboard,
  stack,
  onSave,
  onUpdate,
  onCancel,
  trackClickEvent,
  billingAddress: {},
  errorFields: new Set(),
  billingFlowStepHistory: [BillingFlowSteps.STEP_ONE],
  subscriptionId
})

export const billingFormReducer = (
  state: BillingFormState,
  action: BillingFormAction
) => {
  const {
    SET_ASSETS_PROVIDER,
    SET_PAYMENT_TYPE,
    SET_CONTAINTER_SIZE,
    VALIDATE_BILLING_ADDRESS,
    FETCH_PAYMENT_METHOD_SETTINGS,
    FETCH_PAYMENT_METHOD_SETTINGS_SUCCESS,
    FETCH_PAYMENT_METHOD_SETTINGS_FAIL,
    FETCH_PAYMENT_METHOD_INFO,
    FETCH_PAYMENT_METHOD_INFO_SUCCESS,
    FETCH_PAYMENT_METHOD_INFO_FAIL,
    SET_SAVED_BILLING_ADDRESS,
    SET_PAYMENT_METHOD_BILLING_ADDRESS,
    SAVE_BILLING_ADDRESS_SUCCESS,
    SAVE_BILLING_ADDRESS_FAIL,
    PICKUP_PHC_TOKEN_AND_SYNC_SUCCESS,
    PICKUP_PHC_TOKEN_AND_SYNC_FAIL,
    PUSH_CURRENT_STEP,
    POP_CURRENT_STEP
  } = BillingFormActionType
  const { country, errorFields, billingFlowStepHistory, billingAddress } = state
  const addressValidator = new AddressValidator(country)

  const validateField = (field: string, value?: string) => {
    if (addressValidator.validateField(field, value)) {
      errorFields?.delete(field)
    } else {
      errorFields?.add(field)
    }
  }

  let newState = state
  const { field, value, requiredFields } = action

  switch (action.type) {
    case SET_ASSETS_PROVIDER:
      newState = {
        ...state,
        assetsProvider: AssetsProviderFactory.create(
          action.language as string,
          action.country as string
        )
      }
      break
    case SET_PAYMENT_TYPE:
      newState = {
        ...state,
        paymentType: action.paymentType
      }
      break
    case SET_CONTAINTER_SIZE:
      newState = {
        ...state,
        containerSize: action.containerSize
      }
      break
    case VALIDATE_BILLING_ADDRESS:
      if (field) {
        validateField(field, value)
        newState = {
          ...state,
          billingAddress: {
            ...billingAddress,
            current: {
              ...(billingAddress.current as BillingAddress),
              [field]: value
            }
          },
          errorFields: new Set(errorFields)
        }
      } else {
        requiredFields?.forEach((field) => {
          validateField(field, billingAddress.current?.[field])
        })
        newState = {
          ...state,
          errorFields: new Set(errorFields)
        }
      }
      break
    case FETCH_PAYMENT_METHOD_SETTINGS:
      newState = {
        ...state,
        paymentMethodSettingsLoading: true,
        paymentMethodSettingsError: undefined
      }
      break
    case FETCH_PAYMENT_METHOD_SETTINGS_SUCCESS:
      newState = {
        ...state,
        paymentMethodSettingsLoading: false,
        paymentMethodSettings: action.response?.data
      }
      break
    case FETCH_PAYMENT_METHOD_SETTINGS_FAIL:
      newState = {
        ...state,
        paymentMethodSettingsLoading: false,
        paymentMethodSettingsError: action.error
      }
      break
    case FETCH_PAYMENT_METHOD_INFO:
      newState = {
        ...state,
        paymentMethodInfoLoading: true,
        paymentMethodInfoError: undefined
      }
      break
    case FETCH_PAYMENT_METHOD_INFO_SUCCESS:
      newState = {
        ...state,
        paymentMethodInfoLoading: false,
        paymentMethodInfo: action.response?.data,
        billingAddress: {
          original: action.response?.data?.billingAddress,
          current: action.response?.data?.billingAddress
        }
      }
      break
    case FETCH_PAYMENT_METHOD_INFO_FAIL:
      newState = {
        ...state,
        paymentMethodInfoLoading: false,
        paymentMethodInfoError: action.error
      }
      break
    case PICKUP_PHC_TOKEN_AND_SYNC_SUCCESS:
      newState = {
        ...state,
        pickupPhcTokenAndSyncError: undefined
      }
      break
    case PICKUP_PHC_TOKEN_AND_SYNC_FAIL:
      newState = {
        ...state,
        pickupPhcTokenAndSyncError: action.error
      }
      break
    case SET_SAVED_BILLING_ADDRESS:
      if (action.address) {
        newState = {
          ...state,
          billingAddress: {
            ...billingAddress,
            current: action.address,
            saved: action.address
          }
        }
      }
      break
    case SET_PAYMENT_METHOD_BILLING_ADDRESS:
      if (action.address) {
        newState = {
          ...state,
          paymentMethodInfo: {
            ...(state.paymentMethodInfo as PaymentMethodInfo),
            billingAddress: action.address
          }
        }
      }
      break
    case SAVE_BILLING_ADDRESS_SUCCESS:
      newState = {
        ...state,
        saveBillingAddressError: undefined
      }
      break
    case SAVE_BILLING_ADDRESS_FAIL:
      newState = {
        ...state,
        saveBillingAddressError: action.error
      }
      break
    case PUSH_CURRENT_STEP:
      if (action.currentStep) {
        billingFlowStepHistory.push(action.currentStep)

        newState = {
          ...state,
          billingFlowStepHistory
        }
      }
      break
    case POP_CURRENT_STEP:
      billingFlowStepHistory.pop()

      newState = {
        ...state,
        billingFlowStepHistory
      }
      break
  }

  return newState
}
