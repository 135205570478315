import getSubscriptionItemImage from '@/helpers/getSubscriptionItemImage'
import React from 'react'
import {
  Container,
  StyledSubscriptionContainer,
  StyledTitle,
  SubscriptionLineItemContainer
} from './styles'

export type SubscriptionItemProps = {
  subscriptionType?: string
  subscriptionIds?: string[]
  isDisabled?: boolean
  title?: string
}

const SubscriptionItem = (props: SubscriptionItemProps) => {
  const isDisabled = props.isDisabled
  const title = props.title
  const image = getSubscriptionItemImage(
    props.subscriptionType,
    isDisabled ? 'DISABLED' : 'DEFAULT'
  )
  const subscriptionIdList = props.subscriptionIds
    ? props.subscriptionIds.join(', ')
    : null
  const subscriptionIdContent =
    props.subscriptionIds?.length > 0 ? (
      <>
        <StyledTitle isDisabled={isDisabled}>{title}</StyledTitle>
        <StyledSubscriptionContainer
          data-testid="subscriptionIdsTestId"
          isDisabled={isDisabled}
        >
          {subscriptionIdList}
        </StyledSubscriptionContainer>
      </>
    ) : null
  return (
    <>
      <Container data-testid="subscriptionTestId">
        <SubscriptionLineItemContainer isDisabled={isDisabled}>
          <img src={image} alt="HP All In Plan" />
          {subscriptionIdContent}
        </SubscriptionLineItemContainer>
      </Container>
    </>
  )
}

export default SubscriptionItem
