import styled from 'styled-components'
import tokens from '@veneer/tokens'
import IconChevronRight from '@veneer/core/dist/esm/scripts/icons/icon_chevron_right'
interface StyledDisableProps {
  isDisabled?: boolean
}

export const StyledNoTax = styled.div`
  && {
    font-family: ${tokens.typography.family0};
    color: ${tokens.color.gray6};
    font-size: ${tokens.typography.size2};
    line-height: ${tokens.typography.lineHeight3};
    padding: 6px 0 0 0;
    font-weight: 400;
  }
`

export const StyledDescContainer = styled.div`
  && {
    font-size: ${tokens.typography.size0};
    font-family: ${tokens.typography.family0};
    color: ${tokens.color.gray9};
    line-height: ${tokens.typography.lineHeight1};
    letter-spacing: ${tokens.layout.size0};
    font-weight: 400;
  }
`
export const StyledEditButtonContainer = styled.div<StyledDisableProps>`
  && {
    padding: 0;
    font-size: ${tokens.typography.size1};
    line-height: ${tokens.typography.lineHeight2};
    display: flex;
    align-items: center;
    font-weight: 400;
    a {
      pointer-events: ${(props) => (props.isDisabled ? 'auto' : 'none')};
      cursor: ${(props) => (props.isDisabled ? 'default' : 'none')};
      color: ${(props) =>
        props.isDisabled ? '#0278ab' : tokens.color.highContrastGray};
      display: flex;
      align-items: center;
    }
  }
`
export const StyledTitle = styled.div`
  && {
    font-family: ${tokens.typography.family0};
    font-size: ${tokens.typography.size1};
    line-height: ${tokens.typography.lineHeight2};
    letter-spacing: 0.02em;
    color: ${tokens.color.gray12};
    margin-top: ${tokens.layout.size4};
    font-weight: 400;
  }
`

export const ChevronRightIcon = styled(IconChevronRight)<StyledDisableProps>`
  && {
    pointer-events: ${(props) => (props.isDisabled ? 'auto' : 'none')};
    cursor: ${(props) => (props.isDisabled ? 'default' : 'none')};
    color: ${(props) =>
      props.isDisabled ? '#0278ab' : tokens.color.highContrastGray};
  }
`
export const StyledLine = styled.div`
  border: 1px solid;
  color: #dbdbdb;
  margin-top: ${tokens.layout.size3};
`
