/* eslint-disable @typescript-eslint/no-unused-vars */
import { IPaymentControllerService } from '../../lib/paymentControllerService'
import { AxiosResponse } from 'axios'
import { getMockFixtureOverride } from './mockHelpers'
import {
  paymentMethodInfoFixture,
  paymentMethodSettingsFixture
} from '../fixtures'
import { MockService } from './mockService'

export class MockPaymentControllerService
  extends MockService
  implements IPaymentControllerService
{
  getPaymentMethodSettings(country: string): Promise<AxiosResponse> {
    return this.resolve({
      data: {
        ...paymentMethodSettingsFixture(),
        ...getMockFixtureOverride('paymentMethodSettings')
      },
      status: 200,
      statusText: 'ok',
      headers: {},
      config: {}
    })
  }

  getPaymentMethodInfo(subscriptionId: string): Promise<AxiosResponse> {
    return this.resolve({
      data: {
        ...paymentMethodInfoFixture()
      },
      status: 200,
      statusText: 'ok',
      headers: {},
      config: {}
    })
  }

  updateBillingAddress(
    subscriptionId: string,
    paymentMethodId: string,
    billingAddressId: string
  ): Promise<AxiosResponse<void>> {
    return this.resolve({
      data: {},
      status: 200,
      statusText: 'ok',
      headers: {},
      config: {}
    })
  }

  syncPaymentMethodAndAddress(
    subscriptionId: string,
    paymentMethodId: string,
    billingAddressId: string
  ): Promise<AxiosResponse<void>> {
    return this.resolve({
      data: {},
      status: 200,
      statusText: 'ok',
      headers: {},
      config: {}
    })
  }
}
