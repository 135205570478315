import { PaymentType } from './paymentType'
import { AxiosResponse } from 'axios'
import { BillingAddress } from './billingAddress'
import { BillingFlowSteps } from './billingFlowSteps'
import { ErrorResponse } from './errorResponse'

export enum BillingFormActionType {
  SET_ASSETS_PROVIDER = 'SET_ASSETS_PROVIDER',
  SET_PAYMENT_TYPE = 'SET_PAYMENT_TYPE',
  SET_CONTAINTER_SIZE = 'SET_CONTAINTER_SIZE',
  VALIDATE_BILLING_ADDRESS = 'VALIDATE_BILLING_ADDRESS',
  FETCH_PAYMENT_METHOD_SETTINGS = 'FETCH_PAYMENT_METHOD_SETTINGS',
  FETCH_PAYMENT_METHOD_SETTINGS_SUCCESS = 'FETCH_PAYMENT_METHOD_SETTINGS_SUCCESS',
  FETCH_PAYMENT_METHOD_SETTINGS_FAIL = 'FETCH_PAYMENT_METHOD_SETTINGS_FAIL',
  FETCH_PAYMENT_METHOD_INFO = 'FETCH_PAYMENT_METHOD_INFO',
  FETCH_PAYMENT_METHOD_INFO_SUCCESS = 'FETCH_PAYMENT_METHOD_INFO_SUCCESS',
  FETCH_PAYMENT_METHOD_INFO_FAIL = 'FETCH_PAYMENT_METHOD_INFO_FAIL',
  SET_SAVED_BILLING_ADDRESS = 'SET_SAVED_BILLING_ADDRESS',
  SET_PAYMENT_METHOD_BILLING_ADDRESS = 'SET_PAYMENT_METHOD_BILLING_ADDRESS',
  SAVE_BILLING_ADDRESS_SUCCESS = 'SAVE_BILLING_ADDRESS_SUCCESS',
  SAVE_BILLING_ADDRESS_FAIL = 'SAVE_BILLING_ADDRESS_FAIL',
  PUSH_CURRENT_STEP = 'PUSH_CURRENT_STEP',
  POP_CURRENT_STEP = 'POP_CURRENT_STEP',
  PICKUP_PHC_TOKEN_AND_SYNC_SUCCESS = 'PICKUP_PHC_TOKEN_AND_SYNC_SUCCESS',
  PICKUP_PHC_TOKEN_AND_SYNC_FAIL = 'PICKUP_PHC_TOKEN_AND_SYNC_FAIL'
}

export type BillingFormAction = {
  type: BillingFormActionType
  paymentType?: PaymentType
  containerSize?: string
  language?: string
  country?: string
  field?: string
  value?: string
  requiredFields?: string[]
  address?: BillingAddress
  response?: AxiosResponse
  error?: ErrorResponse
  currentStep?: BillingFlowSteps
}
