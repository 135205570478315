export default function getLocation(): { country: string; language: string } {
  const { locale = 'us/en' } =
    /\/(?<locale>[a-z]{2}\/[a-z]{2})/.exec(window.location.pathname)?.groups ||
    {}

  const [country, language] = locale.split('/')

  return {
    country,
    language
  }
}

export const getLocaleFromLocation = (location) =>
  location?.language?.toLowerCase() + '-' + location?.country?.toUpperCase()

export const getNavigatorLanguage = () => {
  const location = getLocation()
  const localeFromLocation = getLocaleFromLocation(location)

  if (localeFromLocation.length === 5) {
    return localeFromLocation
  }

  return navigator?.languages?.[0] || 'en-US'
}
