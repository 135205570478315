import useShippingClient from '../useShippingClient'
import useDataFetcher from '../useDataFetcher'

const useShippingInfo = () => {
  const shippingClient = useShippingClient()
  return useDataFetcher(
    'shippingInfo',
    shippingClient?.getShippingData
      ? shippingClient.getShippingData.bind(shippingClient)
      : null
  )
}

export default useShippingInfo
