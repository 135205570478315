import React, { FC } from 'react'
import { StyledCreditCardIcon } from './styles'
import { PaymentType } from '../../types'

type PaymentIconProps = {
  paymentMethod: PaymentType
}

export const PaymentIcon: FC<PaymentIconProps> = ({ paymentMethod }) =>
  paymentMethod === 'credit_card' ? (
    <StyledCreditCardIcon data-testid="credit-card-icon" size={32} />
  ) : null
