import styled from 'styled-components'

export const StyledBillingForm = styled.div`
  * {
    box-sizing: border-box;
  }

  > div {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Forma DJR Micro', Arial;
    font-size: 16px;
    line-height: 24px;

    h1,
    h2,
    h3 {
      text-align: center;
    }
  }
`
