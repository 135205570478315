import {
  PaymentMethodInfo,
  PaymentMethodSettings,
  PaymentMethodDetails,
  PaymentType
} from '../../types'
import { billingAddressFixture } from './addressFixtures'

export const paymentMethodSettingsFixture = ({
  merchantName = '70HJG91RV89SC15B811FV59YW5INKUS',
  paymentTypeSettings = {
    credit_card: {
      url: 'https://vphcitg.pgs.ext.hp.com/PGSAllpayment_V2/Payment/Pay'
    }
  },
  businessUnitGuid = '357e06c1-a567-42ba-9548-d57f37e34f0f',
  supportedPaymentTypes = [PaymentType.credit_card]
} = {}): PaymentMethodSettings => ({
  merchantName,
  paymentTypeSettings,
  businessUnitGuid,
  supportedPaymentTypes
})

export const paymentMethodDetailsFixture = ({
  paymentType = 'credit_card',
  status = 'ACTIVE',
  dateAdded = '2022-01-01',
  email = 'john.doe@example.com',
  cardType = 'visa',
  nameOnCard = 'John Doe',
  lastFour = '1234',
  expirationDate = '12/22'
} = {}): PaymentMethodDetails => ({
  paymentType,
  status,
  dateAdded,
  email,
  cardType,
  nameOnCard,
  lastFour,
  expirationDate
})

export const paymentMethodInfoFixture = ({
  billingAddress = billingAddressFixture(),
  paymentMethodDetails = paymentMethodDetailsFixture(),
  paymentProviderId = '1234567890',
  pmsId = '1234567890',
  tenantId = '1234567890'
} = {}): PaymentMethodInfo => ({
  billingAddress,
  paymentMethodDetails,
  paymentProviderId,
  pmsId,
  tenantId
})
