import getAuthProvider from '@/helpers/getAuthProvider'
import getLocalization from '@/helpers/getLocalization'
import useAccount from '@/hooks/useAccount'
import useBillingInfo from '@/hooks/useBillingInfo'
import useGetText from '@/hooks/useGetText'
import useShellContext from '@/hooks/useShellContext'
import {
  BillingModalWindowCancelBtnClicked,
  BillingModalWindowContinueBtnClicked,
  BillingModalWindowDisplayed,
  publishEvent
} from '@/utils/analytics'
import { BillingForm } from '@jarvis/react-billing'
import Card from '@veneer/core/dist/scripts/card'
import Modal from '@veneer/core/dist/scripts/modal'
import React, { useContext } from 'react'
import {
  BillingItemContainer,
  BillingSectionContainer,
  ModalContentContainer,
  StyledAddButtonContainer,
  StyledBillingTileContainer,
  NoBillingText
} from './styles'
import Images from '@/assets/images'
import SubscriptionItem from '@/components/SubscriptionItem'
import { SubscriptionType } from '@/types/SubscriptionType'
import useInstantInkSubscriptionInfo from '@/hooks/useInstantInkSubscriptionInfo'
import useWarningType from '@/hooks/useWarningType'
import { IconChevronRight } from '@veneer/core/dist/scripts/icons'
import { WarningContext } from '@/context/WarningContext'
import getSubscriptionTitleTextId from '@/helpers/getSubscriptionTitleTextId'
import useCookie from '@/hooks/useCookie/useCookies'
const AddPaymentMethod = () => {
  const isAgentSession = useCookie('is_agent_session')
  const billingBaseURLProvider = async () =>
    `${process.env.BILLING_BASE_URL_PROVIDER}/instantink/v1/commerce`
  const { warningType, setWarningType } = useContext(WarningContext)
  const [isOpen, setOpenModal] = React.useState(false)
  const getText = useGetText('billing')
  const shell = useShellContext()
  const authProvider = getAuthProvider()
  const [
    currentBillingModalStep,
    setCurrentBillingModalStep
  ] = React.useState<number>(1)
  const getTextSub = useGetText('subscriptions')
  const title = getTextSub(
    getSubscriptionTitleTextId(SubscriptionType.INSTANT_INK)
  )
  const { forceRefresh } = useBillingInfo()
  const account = useAccount().info
  const { language } = getLocalization()
  const country = account ? account.data?.regionId : process.env.DEFAULT_COUNTRY
  const local = language || process.env.DEFAULT_LANGUAGE + '_' + country

  useWarningType(warningType)

  const {
    info: { data: instantInkSubscriptionInfo }
  } = useInstantInkSubscriptionInfo()
  const subscriptionIds = []
  instantInkSubscriptionInfo?.instantInkSubscriptions?.forEach((item) => {
    subscriptionIds.push(item.accountIdentifier)
  })
  const paymentMethodSubscriptionContent = instantInkSubscriptionInfo &&
    subscriptionIds && (
      <SubscriptionItem
        subscriptionType={SubscriptionType.INSTANT_INK}
        subscriptionIds={subscriptionIds}
        title={title}
      />
    )
  const addBillingClick = () => {
    setOpenModal(!isOpen)
    publishEvent({
      ...BillingModalWindowDisplayed,
      screenMode: 'InstantInk',
      screenName: `Billing1`
    })
  }
  const addPaymentMethodDetailsContent = (
    <StyledBillingTileContainer>
      {paymentMethodSubscriptionContent}
      <div>
        <img
          data-testid="emptyPaymentIcon"
          src={Images.billing}
          alt={getText('addPayment')}
          style={{
            width: '30px',
            height: '25px'
          }}
        />
        <NoBillingText>{getText('noBilling')}</NoBillingText>
        <StyledAddButtonContainer>
          <a
            role="button"
            data-testid="addBillingButton"
            onClick={addBillingClick}
          >
            {getText('addPayment')}
            <IconChevronRight
              size={20}
              customStyle={{
                marginLeft: '5px',
                color: '#0278ab',
                verticalAlign: 'middle'
              }}
            />
          </a>
        </StyledAddButtonContainer>
      </div>
    </StyledBillingTileContainer>
  )

  const handleCloseModal = () => {
    setOpenModal(false)
    publishEvent({
      ...BillingModalWindowCancelBtnClicked,
      screenMode: 'InstantInk',
      screenName: `Billing${currentBillingModalStep}`
    })
    setCurrentBillingModalStep(1)
  }
  const onSave = async () => {
    setOpenModal(false)
    publishEvent({
      ...BillingModalWindowContinueBtnClicked,
      screenMode: 'InstantInk',
      screenName: `Billing2`
    })
    forceRefresh()
    if (warningType === 'billingWarning') {
      setWarningType('noWarning')
    } else if (warningType === 'shippingAndBillingWarning') {
      setWarningType('shippingWarning')
    }
  }
  const handleUpdate = () => {
    publishEvent({
      ...BillingModalWindowContinueBtnClicked,
      screenMode: 'InstantInk',
      screenName: `Billing1`
    })
    publishEvent({
      ...BillingModalWindowDisplayed,
      screenMode: 'InstantInk',
      screenName: `Billing2`
    })
    setCurrentBillingModalStep(2)
  }
  const addPaymentMethodTileContent = (
    <BillingSectionContainer>
      {addPaymentMethodDetailsContent}
      <Modal closeButton show={isOpen} onClose={handleCloseModal}>
        {isOpen && (
          <ModalContentContainer>
            <BillingForm
              accountIdentifier={'testingId'}
              country={country}
              language={local}
              baseURLProvider={billingBaseURLProvider}
              authProvider={authProvider}
              mockStratus={false}
              onSave={onSave}
              onCancel={handleCloseModal}
              onUpdate={handleUpdate}
              stack={shell?.stack}
              virtualKeyboard={isAgentSession}
              nativeApp={shell?.isNative}
            />
          </ModalContentContainer>
        )}
      </Modal>
    </BillingSectionContainer>
  )
  return (
    <>
      <BillingItemContainer>
        <Card
          content={addPaymentMethodTileContent}
          className="card-box"
          customStyle={{ borderRadius: 8 }}
        />
      </BillingItemContainer>
    </>
  )
}

export default AddPaymentMethod
