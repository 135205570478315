import { AxiosResponse } from 'axios'
import { IAddressService } from '../../lib/addressService'
import { BillingAddress, NewBillingAddress } from '../../types'
import { MockService } from './mockService'
import { billingAddressFixture } from '../fixtures'

export class MockAddressService extends MockService implements IAddressService {
  createAddress(
    address: NewBillingAddress
  ): Promise<AxiosResponse<BillingAddress>> {
    return this.resolve({
      data: {
        ...billingAddressFixture(address)
      },
      status: 200,
      statusText: 'ok',
      headers: {},
      config: {}
    })
  }

  updateAddress(
    address: BillingAddress
  ): Promise<AxiosResponse<BillingAddress>> {
    return this.resolve({
      data: {
        ...billingAddressFixture(address)
      },
      status: 200,
      statusText: 'ok',
      headers: {},
      config: {}
    })
  }
}
