import styled from 'styled-components'
import tokens from '@veneer/tokens'
interface StyledDisableProps {
  isDisabled?: boolean
}
export const ModalContentContainer = styled.div`
  & .shipping-content .shipping-fields {
    width: auto;
  }
`

export const BillingItemContainer = styled.div<StyledDisableProps>`
  && {
    width: 48%;
    font-family: ${tokens.typography.family0};
    font-size: ${tokens.typography.size2};
    font-weight: 400;
    margin: 24px 16px 0 0;
    pointer-events: ${(props) => (props.isDisabled ? 'none' : 'auto')};
    cursor: ${(props) => (props.isDisabled ? 'none' : 'default')};
    opacity: ${(props) => (props.isDisabled ? 0.5 : 1)};
    && {
      @media only screen and (max-width: ${tokens.layout.smMax}) {
        width: 100%;
      }
    }
  }
`

export const BillingSectionContainer = styled.div`
  margin: 24px 24px;
  font-family: ${tokens.typography.family0};
  font-weight: 400;
`

export const StyledBillingTileContainer = styled.div`
  && {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 210px;
  }
`

export const StyledBillingDetailsContainer = styled.div`
  && {
    font-family: ${tokens.typography.family0};
    font-size: ${tokens.typography.size1};
    font-weight: 400;
    line-height: 16px;
    color: ${tokens.color.gray9};
    padding: 4px 0 0 0;
    letter-spacing: 0.02em;
  }
`

export const StyledEditButtonContainer = styled.div<StyledDisableProps>`
  && {
    padding: 0;
    font-size: ${tokens.typography.size1};
    line-height: ${tokens.typography.lineHeight2};
    a {
      pointer-events: ${(props) => (props.isDisabled ? 'none' : 'auto')};
      cursor: ${(props) => (props.isDisabled ? 'none' : 'default')};
      color: ${(props) =>
        props.isDisabled
          ? tokens.color.highContrastGray
          : 'rgba(0, 118, 173, 1)'};

  }
`
