import styled, { css } from 'styled-components'
import Card from '@veneer/core/dist/scripts/card'
import TextBox from '@veneer/core/dist/scripts/text_box'
import Select from '@veneer/core/dist/scripts/select'
import CreditCard from '@veneer/core/dist/scripts/icons/icon_credit_card'

export const StyledBillingTypeSelector = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  width: 95%;
  margin-top: 24px;
  overflow-wrap: anywhere;
`

export const StyledSubtitle = styled.span`
  text-align: left;
  margin-bottom: 8px;
  font-family: 'Forma DJR Micro Medium', Arial Bold;
  letter-spacing: 0.8px;
  line-height: 20px;

  ${(props) => {
    const responsive = {
      'desktop-container': css`
        font-size: 20px;
        letter-spacing: 1px;
        line-height: 28px;
      `,
      'tablet-landscape-container': css`
        font-size: 20px;
        letter-spacing: 0.9px;
        line-height: 28px;
      `
    }
    return responsive[props.theme.containerSize]
  }}
`

export const StyledTypeSelectorCards = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  ${(props) => {
    if (
      ['tablet-landscape-container', 'desktop-container'].includes(
        props.theme.containerSize
      )
    ) {
      return css`
        flex-direction: row;
      `
    }
  }}
`

export const StyledTypeSelectorCard = styled(Card)`
  margin-bottom: 16px;
  flex: 1 1 0;
  max-width: none;
  padding: 16px;

  &:last-child {
    margin-bottom: 0;
  }

  ${(props) => {
    if (
      ['tablet-landscape-container', 'desktop-container'].includes(
        props.theme.containerSize
      )
    ) {
      return css`
        max-width: 182px;
        margin-right: 16px;
        margin-bottom: 0;

        &:last-child {
          margin-right: 0;
        }
      `
    }
  }}
`

export const StyledCardContent = styled.div`
  display: flex;
  align-items: center;
  align-content: space-between;
`

export const StyledVeneerRadio = styled.div`
  flex: auto;

  > label {
    display: flex;
    align-items: start;
  }
`

export const StyledVeneerRadioContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-top: 0;
  text-align: left;
  white-space: normal;
`

export const StyledTypeSelectorIcon = styled.div`
  vertical-align: middle;
  height: 32px;
`

export const StyledCreditCardIcon = styled(CreditCard)`
  margin-top: -4px;
`

export const StyledAddressSectionContainer = styled.div`
  width: 95%;
  margin-top: 24px;
`

export const StyledFormFields = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const StyledInputGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${(props) => {
    const tabLandDesk = css`
      div:first-child {
        margin-right: 8px;
      }
    `

    const responsive = {
      'tablet-landscape-container': tabLandDesk,
      'desktop-container': tabLandDesk
    }
    return responsive[props.theme.containerSize]
  }}

  ${(props) => {
    const tabLandDesk = css`
      flex-direction: row;
      align-items: normal;
    `

    const responsive = {
      'tablet-landscape-container': tabLandDesk,
      'desktop-container': tabLandDesk
    }
    return responsive[props.theme.containerSize]
  }}
`

export const StyledTextBox = styled(TextBox)`
  margin: ${(props) => (props['$no-margin'] ? '0' : '8px 0')};
  width: 100%;

  ${(props) =>
    props.label === 'Umsatzsteuer-Identifikationsnummer (optional)' &&
    `
    @media screen and (max-width: 500px) {
      label {
        overflow: visible;
        overflow-wrap: break-word;
        white-space: normal;
      }

      div {
        max-height: unset;
        height: 80px;
      }
    }
  `}
`

export const StyledSelect = styled(Select)`
  margin: 8px 0;
  width: 100%;

  ${(props) => {
    const tabLandDesk = css`
      margin-right: 8px;

      &:last-child {
        margin-right: 0;
      }
    `

    const responsive = {
      'tablet-landscape-container': tabLandDesk,
      'desktop-container': tabLandDesk
    }
    return responsive[props.theme.containerSize]
  }}
`
