import styled from 'styled-components'
import tokens from '@veneer/tokens'

export const StyledTextContainer = styled.div`
  && {
    font-size: ${tokens.typography.size2};
    font-weight: 400;
    font-family: ${tokens.typography.family0};
    color: ${tokens.color.gray9};
    line-height: ${tokens.typography.lineHeight3};
    padding: 12px 0;
    letter-spacing: 0.02em;
  }
`
export const ErrorItemContainer = styled.div`
  && {
    font-family: ${tokens.typography.family0};
    font-size: ${tokens.typography.size2};
    font-weight: 400;
    padding: 24px;
    margin-bottom: 5px;
    min-height: 240px;

    && {
      @media only screen and (max-width: ${tokens.layout.smMax}) {
        width: 100%;
      }
    }
  }
`
export const StyledIconError = styled.div`
  && {
    font-family: ${tokens.typography.family0};
    display: flex;
    font-weight: 400;
    padding: 0 0 10px 0;

    img {
      width: 57px;
    }
  }
`
