import React from 'react'
import { useBillingFormState, useErrorFields, useGetText } from '../../hooks'
import { StyledErrorMessageInline } from './styles'
import IconMinusCircle from '@veneer/core/dist/scripts/icons/icon_minus_circle'

export const ErrorMessageInline = () => {
  const getText = useGetText()
  const errorFields = useErrorFields()
  const { saveBillingAddressError } = useBillingFormState()

  let message
  if (saveBillingAddressError) {
    message = getText('error_message.something_went_wrong')
  } else if (errorFields.size > 0) {
    message = getText('error_message.complete_required')
  }

  if (message) {
    return (
      <StyledErrorMessageInline>
        <IconMinusCircle size={24} color="colorRed6" filled />
        <span>{message}</span>
      </StyledErrorMessageInline>
    )
  }

  return null
}
