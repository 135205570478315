import useUserMgtSvcClient from '../useUserMgtSvcClient'
import useDataFetcher from '../useDataFetcher'

const useUserManagementInfo = () => {
  const userMgtSvcClient = useUserMgtSvcClient()

  return useDataFetcher(
    'userInfo',
    userMgtSvcClient?.getCurrentActiveUser
      ? userMgtSvcClient.getCurrentActiveUser.bind(userMgtSvcClient)
      : null
  )
}
export default useUserManagementInfo
