import { BillingAddress } from '../../types'

export const billingAddressFixture = ({
  address = '123 Main St.',
  address2 = 'Apt 4',
  addressType = 'billing',
  city = 'San Francisco',
  company = 'ACME Inc.',
  countryCode = 'US',
  createdDate = '2022-01-01',
  dateLastScreened = '2022-01-01',
  firstName = 'John',
  bisPrimary = true,
  lastName = 'Doe',
  phoneNumber = '+1-415-555-1234',
  postalCode = '94105',
  resourceId = '1234567890',
  rplState = 'CA',
  state = 'CA',
  tenantId = '1234567890'
} = {}): BillingAddress => ({
  address,
  address2,
  addressType,
  city,
  company,
  countryCode,
  createdDate,
  dateLastScreened,
  firstName,
  bisPrimary,
  lastName,
  phoneNumber,
  postalCode,
  resourceId,
  rplState,
  state,
  tenantId
})
