import { AxiosResponse } from 'axios'
import { PaginatedResponse } from '@/types/pendingChanges'
import { StratusClient } from '@jarvis/web-stratus-client/dist/src/clients/stratus-client'
import { Stack } from '@jarvis/web-stratus-client'
import { JarvisAuthProvider } from '@jarvis/web-http'

const baseUrl = {
  [Stack.prod]: 'https://us1.api.ws-hp.com/subscription-state/v1/subscriptions',
  [Stack.stage]:
    'https://stage-us1.api.ws-hp.com/subscription-state/v1/subscriptions',
  [Stack.pie]:
    'https://pie-us1.api.ws-hp.com/subscription-state/v1/subscriptions',
  [Stack.dev]:
    'https://pie-us1.api.ws-hp.com/subscription-state/v1/subscriptions'
}

export class PendingChangesClient extends StratusClient {
  constructor(authToken: JarvisAuthProvider, stack: Stack) {
    super(baseUrl[stack], authToken)
  }

  getPendingChanges(
    subscriptionId: string
  ): Promise<AxiosResponse<PaginatedResponse>> {
    return this.jarvisWebHttpInstance.get({
      url: `/${subscriptionId}/pending-changes`
    })
  }
}
