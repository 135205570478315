import { ActionsType } from './Actions'
import InfoKeys from '@/types/InfoKeys'
import InfoErrors from '@/types/InfoErrors'

type ReturnSetErrorFetch<K extends InfoKeys> = {
  type: ActionsType.SET_ERROR_FETCH
  data: {
    key: K
    error: InfoErrors[K]
  }
}

export default function setErrorFetch(
  key: InfoKeys,
  error: InfoErrors[InfoKeys]
): ReturnSetErrorFetch<InfoKeys> {
  return {
    type: ActionsType.SET_ERROR_FETCH,
    data: {
      key,
      error
    }
  }
}
