import { JarvisAuthProvider } from '@jarvis/web-http'
import axios, { AxiosInstance } from 'axios'
import InfoDatas from 'src/types/InfoDatas'
import { forceLoginHandling } from '@/utils/forceLoginHandling'
import sendOpenTelemetryEvent from '@/helpers/sendOpenTelemetryEvent'
import { TaxpayerDetails } from '@/types/TaxpayerDetails'

export default class StoreFrontClient {
  httpClient: AxiosInstance
  xCorrelationId: string
  baseURL: string
  mfeAuthProvider: JarvisAuthProvider

  constructor(authProvider: JarvisAuthProvider, xCorrelationId: string) {
    this.httpClient = axios
    this.httpClient.defaults.params = {}
    this.mfeAuthProvider = authProvider
    this.xCorrelationId = xCorrelationId

    this.baseURL = `${process.env.BILLING_BASE_URL_PROVIDER}/instantink/v1/commerce`
  }

  async getBillingData(): Promise<InfoDatas['billingInfo']> {
    const stratusToken = await this.mfeAuthProvider.getAccessToken()
    const headers = {
      accept: 'application/json',
      authorization: `Bearer ${stratusToken}`
    }

    return this.httpClient
      .get(`${this.baseURL}/billing`, {
        headers
      })
      .then(({ data }) => data)
      .catch((err) => {
        if (err?.response?.status === 403) {
          forceLoginHandling()
        }
        sendOpenTelemetryEvent(
          'Error while fetching billing data from storefront client: ' +
            JSON.stringify(err)
        )
        throw err
      })
  }

  async getInstantInkSubscriptionData(): Promise<
    InfoDatas['instantInkSubscriptionInfo']
  > {
    const stratusToken = await this.mfeAuthProvider.getAccessToken()
    const headers = {
      accept: 'application/json',
      authorization: `Bearer ${stratusToken}`
    }

    return this.httpClient
      .get(`${this.baseURL}/subscriptions`, {
        headers
      })
      .then(({ data }) => data)
      .catch((err) => {
        if (err?.response?.status === 403) {
          forceLoginHandling()
        }
        sendOpenTelemetryEvent(
          'Error while fetching II subscription data from storefront client: ' +
            JSON.stringify(err)
        )
        throw err
      })
  }

  async getTaxIdData(): Promise<TaxpayerDetails> {
    const stratusToken = await this.mfeAuthProvider.getAccessToken()
    const headers = {
      accept: 'application/json',
      authorization: `Bearer ${stratusToken}`
    }

    return await this.httpClient
      .get(`${this.baseURL}/billing/taxpayer_details`, {
        headers
      })
      .then(({ data }) => {
        return data
      })
      .catch((err) => {
        sendOpenTelemetryEvent(
          `Error while fetching  data from taxpayer_details api : ` +
            JSON.stringify(err)
        )
        throw err
      })
  }

  defaultHeaders() {
    if (this.xCorrelationId) {
      return {
        headers: {
          'X-Correlation-ID': this.xCorrelationId
        }
      }
    }
    return {}
  }
}
