import React from 'react'
import { BaseURLProvider, Stack } from '@jarvis/web-stratus-client'
import { JarvisAuthProvider } from '@jarvis/web-http'
import { BillingFormProvider } from './billingFormProvider'
import { BillingFormContainer } from './billingFormContainer'
import { ErrorType, DataType } from '../../types'
import '@veneer/styles/typography/typography.css'
import { GlobalStyle } from '../../styles/styles'

export type BillingFormProps = {
  /**
   * subscriptionId used to manage associated payment method info
   */
  subscriptionId: string

  /**
   * enum of (dev,pie,stage,prod) used for stratus web calls
   */
  stack: Stack

  /** Country */
  country: string

  /** Language */
  language: string

  /**
   * authentication provider object that must include the getAccessToken function
   */
  authProvider: JarvisAuthProvider

  /**
   * function to get the API base URLs
   *
   * - apiName and apiVersion can be used to query Services Discovery API
   */
  baseURLProvider?: BaseURLProvider

  /**
   * xCorrelationId used for logging
   */
  xCorrelationId?: string

  /**
   * mock flag, if set to true then all Billing API calls will be mocked
   */
  mockStratus?: boolean

  /**
   * true if Jarvis Billing is running inside a webview, false otherwise
   */
  nativeApp?: boolean

  /**
   * true if we want to show the virtual keyboard
   */
  virtualKeyboard?: boolean

  /**
   * function called when billing data is updated, if an error parameter is present then the save failed.
   *
   * Possible error values:
   *
   * - 'expired\_critical\_scope'
   * - 'expired\_token'
   */
  onSave?: (error?: ErrorType) => void

  /**
   * function called when dataType is successfully updated, not when an error occurs.
   *
   * Possible dataType values:
   *
   * - 'accountType'
   * - 'billingAddress'
   */
  onUpdate?: (dataType?: DataType) => void

  /**
   * function called when user cancels
   */
  onCancel?: () => void

  /**
   * function called to track click events, used for analytics
   */
  trackClickEvent?: (linkId: string, linkPlacement: string) => void
}

/**
 * React component that renders the billing form.
 */
export const BillingForm = (props: BillingFormProps) => {
  return (
    <>
      <GlobalStyle />
      <BillingFormProvider {...props}>
        <BillingFormContainer />
      </BillingFormProvider>
    </>
  )
}

BillingForm.defaultProps = {
  onSave: () => null,
  onUpdate: () => null,
  onCancel: () => null,
  trackClickEvent: () => null
}
