import React, { useCallback, useEffect, useState } from 'react'
import Button from '@veneer/core/dist/scripts/button'
import RadioButton from '@veneer/core/dist/scripts/radio_button'
import {
  DESKTOP_CONTAINER,
  MOBILE_CONTAINER,
  TABLET_LANDSCAPE_CONTAINER,
  TABLET_PORTRAIT_CONTAINER,
  AsyncDispatch,
  usePaymentMethodInfo,
  usePaymentMethodInfoError,
  useContainerSize,
  useDispatch,
  useErrorFields,
  useGetText,
  useOnCancel,
  useOnSave,
  usePaymentType,
  usePaymentMethodSettings,
  usePaymentMethodSettingsError,
  useStateOptions
} from '../../hooks'
import {
  BillingFlowSteps,
  ErrorType,
  PaymentType,
  BillingFormAction,
  BillingFormState
} from '../../types'
import { Spinner } from '../Spinner'
import {
  fetchPaymentMethodInfoAction,
  fetchPaymentMethodSettingsAction,
  pushCurrentStepAction,
  saveBillingAddressAction,
  setPaymentTypeAction,
  validateBillingAddressAction
} from '../../actions'
import { ErrorMessagePage } from '../ErrorMessagePage'
import { AddressSection } from './addressSection'
import { getRequiredFields } from '../Helpers/helpers'
import { PaymentIcon } from './paymentIcon'
import { ErrorMessageInline } from '../ErrorMessageInline'
import {
  StyledBillingTypeSelector,
  StyledTypeSelectorCards,
  StyledSubtitle,
  StyledTypeSelectorCard,
  StyledCardContent,
  StyledVeneerRadio,
  StyledVeneerRadioContent,
  StyledTypeSelectorIcon,
  StyledAddressSectionContainer
} from './styles'
import {
  StyledStep,
  StyledHeader,
  StyledStepTitle,
  StyledStepNumber,
  StyledButtonSection
} from '../Shared/styles'

const StepOneSubTitles = () => {
  const getText = useGetText()

  return (
    <StyledHeader>
      <StyledStepNumber>
        {getText('billing_form.step_title.1')}:{' '}
      </StyledStepNumber>
      <StyledStepTitle>{getText('billing_address.sub_title')}</StyledStepTitle>
    </StyledHeader>
  )
}

export const StepOne = () => {
  const getText = useGetText()
  const containerSize = useContainerSize()
  const paymentMethodSettings = usePaymentMethodSettings()
  const paymentMethodSettingsError = usePaymentMethodSettingsError()
  const paymentMethodInfo = usePaymentMethodInfo()
  const paymentMethodInfoError = usePaymentMethodInfoError()
  const errorFields = useErrorFields()
  const onSave = useOnSave()
  const onCancel = useOnCancel()
  const dispatch = useDispatch()
  const stateOptions = useStateOptions()
  const showStateDropdown = stateOptions.length > 0
  const paymentType = usePaymentType()
  const [ready, setReady] = useState(false)
  const [saving, setSaving] = useState(false)
  const selectPaymentType = useCallback(
    (paymentType) => dispatch(setPaymentTypeAction(paymentType)),
    [dispatch]
  )
  const paymentMethods = paymentMethodSettings?.supportedPaymentTypes

  useEffect(() => {
    if (!ready) {
      if (
        paymentMethodInfo !== undefined &&
        paymentMethodSettings !== undefined
      ) {
        setReady(true)
      }
    }
  }, [ready, paymentMethodInfo, paymentMethodSettings])

  useEffect(() => {
    if (paymentMethodInfo?.paymentMethodDetails && !paymentType) {
      ;(async () => {
        await selectPaymentType(
          paymentMethodInfo.paymentMethodDetails.paymentType ||
            PaymentType.credit_card
        )
      })()
    }
  }, [paymentMethodInfo, paymentType, selectPaymentType])

  if (!ready) {
    if (paymentMethodInfoError || paymentMethodSettingsError) {
      const onRetry = async () => {
        const promises = []

        if (paymentMethodInfoError) {
          promises.push(dispatch(fetchPaymentMethodInfoAction()))
        }

        if (paymentMethodSettingsError) {
          promises.push(dispatch(fetchPaymentMethodSettingsAction()))
        }

        try {
          await Promise.all(promises)
        } catch {
          // do nothing
        }
      }

      return (
        <>
          <StepOneSubTitles />
          <ErrorMessagePage onRetry={onRetry} />
        </>
      )
    }
    return <Spinner />
  }

  if (!paymentMethods) {
    return null
  }

  const onContinue = async (
    dispatch: AsyncDispatch<BillingFormAction, BillingFormState, void>,
    getState: () => BillingFormState
  ) => {
    try {
      setSaving(true)

      await dispatch(
        validateBillingAddressAction(getRequiredFields(showStateDropdown))
      )
      if (getState().errorFields.size > 0) return

      await dispatch(saveBillingAddressAction())

      await dispatch(pushCurrentStepAction(BillingFlowSteps.STEP_TWO))
    } catch (error) {
      const { response } = error
      if (response?.status === 401) {
        onSave(ErrorType.expired_token)
      } else if (response?.status === 403) {
        onSave(ErrorType.expired_critical_scope)
      }
      setSaving(false)
    }
  }

  const showMobilePaymentTypeSelector =
    containerSize &&
    ![TABLET_LANDSCAPE_CONTAINER, DESKTOP_CONTAINER].includes(containerSize)

  return (
    <StyledStep>
      <StepOneSubTitles />
      <StyledBillingTypeSelector>
        <StyledSubtitle>
          {getText('billing_type_selector.sub_title')}
        </StyledSubtitle>
        <StyledTypeSelectorCards>
          {paymentMethods.map((paymentMethod: PaymentType, index: number) => (
            <StyledTypeSelectorCard
              key={index}
              appearance="dropShadow"
              content={
                <StyledCardContent>
                  <StyledVeneerRadio>
                    <RadioButton
                      key={index}
                      label={
                        <>
                          <StyledVeneerRadioContent>
                            {!showMobilePaymentTypeSelector ? (
                              <StyledTypeSelectorIcon>
                                <PaymentIcon paymentMethod={paymentMethod} />
                              </StyledTypeSelectorIcon>
                            ) : null}
                            {getText(
                              `billing_type_selector.${paymentMethod}.title`
                            )}
                          </StyledVeneerRadioContent>
                        </>
                      }
                      data-testid={`${paymentMethod}-radio-button`.replace(
                        '_',
                        '-'
                      )}
                      data-analyticsid={`${paymentMethod}-radio-button`}
                      checked={paymentType === paymentMethod}
                      onChange={async () => {
                        await selectPaymentType(paymentMethod)
                      }}
                    />
                  </StyledVeneerRadio>
                  {showMobilePaymentTypeSelector ? (
                    <StyledTypeSelectorIcon>
                      <PaymentIcon paymentMethod={paymentMethod} />
                    </StyledTypeSelectorIcon>
                  ) : null}
                </StyledCardContent>
              }
            />
          ))}
        </StyledTypeSelectorCards>
      </StyledBillingTypeSelector>
      <StyledAddressSectionContainer>
        <div data-testid="address-section">
          <AddressSection showStateDropdown={showStateDropdown} />
        </div>
      </StyledAddressSectionContainer>
      <ErrorMessageInline />
      <StyledButtonSection>
        <Button
          data-testid="cancel-button"
          data-analyticsid="CancelButton"
          appearance="secondary"
          onClick={onCancel}
          expanded={
            containerSize === MOBILE_CONTAINER ||
            containerSize === TABLET_PORTRAIT_CONTAINER
          }
        >
          {getText('billing_form.cancel')}
        </Button>
        <Button
          data-testid="continue-button"
          data-analyticsid="ContinueButton"
          disabled={errorFields.size > 0}
          loading={saving}
          onClick={() => dispatch(onContinue)}
          expanded={
            containerSize === MOBILE_CONTAINER ||
            containerSize === TABLET_PORTRAIT_CONTAINER
          }
        >
          {getText('billing_form.continue')}
        </Button>
      </StyledButtonSection>
    </StyledStep>
  )
}
