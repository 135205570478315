import React, { FC, useState } from 'react'
import useGetText from '@/hooks/useGetText'
import Modal from '@veneer/core/dist/scripts/modal'
import { useAppContext } from '@/context/AppContext'
import getLocalization from '@/helpers/getLocalization'
import { setInlineNotification } from '@/actions/Actions'
import { TaxIdForm } from '@jarvis/react-billing'
import useShellContext from '@/hooks/useShellContext'
import getAuthProvider from '@/helpers/getAuthProvider'

import {
  StyledTitle,
  StyledNoTax,
  StyledDescContainer,
  StyledEditButtonContainer,
  ChevronRightIcon,
  StyledLine
} from './styles'

interface HandleTaxInfoProps {
  taxId: string
  nonProfitTaxId: string
  allowEditingTaxId: boolean
  forceRefresh: () => void
}
const HandleTaxInfo: FC<HandleTaxInfoProps> = ({
  taxId,
  nonProfitTaxId,
  allowEditingTaxId,
  forceRefresh
}) => {
  const getText = useGetText('billing')
  const getTaxData = useGetText('taxData')
  const authProvider = getAuthProvider()
  const { language } = getLocalization()
  const { state, dispatch } = useAppContext()
  const shell = useShellContext()
  const country = state.account
    ? state.account.data.regionId
    : process.env.DEFAULT_COUNTRY
  const currLanguage = language || process.env.DEFAULT_LANGUAGE
  const handleTaxOrVat = () => (country === 'US' ? 'Tax' : 'Vat')
  const taxOrVat = handleTaxOrVat()
  const [openTaxIdModal, setOpenTaxIdModal] = useState<boolean>(false)
  const handleTaxIdModal = () => {
    setOpenTaxIdModal(!openTaxIdModal)
  }

  const onSave = (err: string) => {
    handleTaxIdModal()
    const notification = err
      ? {
          display: true,
          title: getText('failedApi', {
            defaultValue: 'Something went wrong. Try again later.'
          }),
          status: 'warning' as const
        }
      : {
          display: true,
          title: getTaxData('onSaveNotification', {
            taxOrVat,
            defaultValue: `Your ${taxOrVat} ID was successfully saved.`
          }),
          status: 'positive' as const
        }
    dispatch(setInlineNotification(notification))
    forceRefresh()
  }

  return (
    <>
      <StyledLine />
      {taxId || nonProfitTaxId ? (
        <StyledTitle>
          {`${getTaxData('taxIdTitle', {
            taxOrVat,
            defaultValue: `${taxOrVat} ID: `
          })}${taxId ? taxId : nonProfitTaxId}`}
        </StyledTitle>
      ) : (
        <StyledNoTax isDisabled={taxId}>
          {getTaxData('noTaxId', {
            taxOrVat,
            defaultValue: `No ${taxOrVat} ID has been added (Optional)`
          })}
        </StyledNoTax>
      )}
      <StyledDescContainer>
        {getTaxData('description', {
          taxOrVat,
          defaultValue: `Your ${taxOrVat} ID will be shown on your monthly billing invoices.`
        })}
      </StyledDescContainer>
      <StyledEditButtonContainer
        isDisabled={allowEditingTaxId || (!taxId && !nonProfitTaxId)}
      >
        <a role="button" data-testid="tax-id-btn" onClick={handleTaxIdModal}>
          {taxId || nonProfitTaxId
            ? getTaxData('updateTaxId', {
                taxOrVat,
                defaultValue: `Update ${taxOrVat} ID`
              })
            : getTaxData('addTaxId', {
                taxOrVat,
                defaultValue: `Add ${taxOrVat} ID`
              })}
          <ChevronRightIcon
            isDisabled={allowEditingTaxId || (!taxId && !nonProfitTaxId)}
          />
        </a>
      </StyledEditButtonContainer>

      {openTaxIdModal && (
        <Modal
          align="start"
          closeButton
          id="modal-id"
          show={openTaxIdModal}
          data-testid="tax-id-modal"
          onClose={handleTaxIdModal}
        >
          <TaxIdForm
            country={country}
            language={currLanguage}
            authProvider={authProvider}
            stack={shell?.stack}
            onSave={(err: string) => onSave(err)}
            onCancel={handleTaxIdModal}
          />
        </Modal>
      )}
    </>
  )
}

export default HandleTaxInfo
