import styled from 'styled-components'
import tokens from '@veneer/tokens'

export const ModalContentContainer = styled.div`
  & .shipping-content .shipping-fields {
    width: auto;
  }
`

export const BillingItemContainer = styled.div`
  && {
    font-family: ${tokens.typography.family0};
    font-size: ${tokens.typography.size2};
    font-weight: 400;
    width: 48%;

    && {
      @media only screen and (max-width: ${tokens.layout.smMax}) {
        width: 100%;
      }
    }
  }
`

export const BillingSectionContainer = styled.div`
  font-family: ${tokens.typography.family0};
  font-weight: 400;
`

export const StyledBillingTileContainer = styled.div`
  && {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 240px;
    padding: ${tokens.layout.size4};
  }
`

export const NoBillingText = styled.div`
  && {
    color: #737373;
    font-size: ${tokens.typography.size1};
  }
`

export const StyledBillingDetailsContainer = styled.div`
  && {
    font-family: ${tokens.typography.family0};
    font-size: ${tokens.typography.size1};
    font-weight: 400;
    line-height: 16px;
    color: ${tokens.color.gray9};
    padding: 4px 0 0 0;
    letter-spacing: 0.02em;
  }
`

export const StyledAddButtonContainer = styled.div`
  && {
    padding: 0;
    font-size: ${tokens.typography.size1};
    line-height: ${tokens.typography.lineHeight2};
    a {
      cursor: pointer;
      color: #0278ab;
    }
  }
`
