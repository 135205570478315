import { EDelinquencyNotification } from '@/types/pendingChanges'
import moment from 'moment'

export const getExpirationStatus = (expirationDate: string) => {
  if (!expirationDate) return null

  const [expirationMonth, expirationYear] = expirationDate
    .split('/')
    .map((part) => parseInt(part, 10))

  const currentDay = moment()
  const cardExpiryDate = moment()
    .month(expirationMonth - 1)
    .year(expirationYear)
    .endOf('month')
  const hasExpired = moment(currentDay).isAfter(cardExpiryDate)
  const isAboutToExpire =
    moment(cardExpiryDate).diff(moment(currentDay), 'month', true) < 2

  const expirationStatus = hasExpired
    ? EDelinquencyNotification.EXPIRED
    : isAboutToExpire
    ? EDelinquencyNotification.ABOUT_TO_EXPIRE
    : EDelinquencyNotification.NONE
  return { notificationStatus: expirationStatus }
}
