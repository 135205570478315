import useStoreFrontClient from '../useStoreFrontClient'
import useDataFetcher from '../useDataFetcher'
import useIsInstantInkEnabled from '../useIsInstantInkEnabled/useIsInstantInkEnabled'

const useBillingInfo = () => {
  const storeFrontClient = useStoreFrontClient()
  return useDataFetcher(
    'billingInfo',
    useIsInstantInkEnabled() && storeFrontClient?.getBillingData
      ? storeFrontClient.getBillingData.bind(storeFrontClient)
      : null
  )
}

export default useBillingInfo
