import { useState, useEffect } from 'react'
import getAuthProvider from '@/helpers/getAuthProvider'
import AccountClient from '../../services/AccountClient'
import useShellContext from '../useShellContext'

export default function useAccountClient() {
  const [accountClient, setAccountClient] = useState(null)
  const shellContext = useShellContext()
  const authProvider = getAuthProvider()

  useEffect(() => {
    setAccountClient(
      new AccountClient(authProvider, shellContext?.xCorrelationId + '')
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return accountClient
}
